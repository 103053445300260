import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
  state: () => {
    return { 
        
        idp:'',
        
        
        datosRegistrados:0,
        datosValidados:0,
        documentosCargados:0,
        documentosValidados:0,
        
        descargaComprobanteInscripcion:0,
        numeroControl:'',
        sistema:'',
        carrera:'',
        horario:'',
        registroView:true,
        principalView:false,

        currentView:'PRINCIPAL',

        idAlCur:'',//c0
        curp:'',//c1
        userName:'',//c2
        nombre:'',//c3
        ap1:'',//c4
        ap2:'',//c5
        correo:'',//c6
        anioRegistro:'',//c7
        telefono:'',//c8
        correoVerificado:0,//c9
        datosVerificados:0,//c10
        relacionGrupoChedraui:'',//c11
        valido:'',//c12
        enfermedad:'',//c13
        alergia:'',//c14
        medicamento:'',//c15

        
        mensajes:0,

        status:'',
        datosStatus:0,        
        documentosStatus:0,
        fichaRegistro:0,
        comprobanteInscripcionDescargado:0,
        registroCompleto:0,
        userID:'',        
        plantel:'',
        login:0,        
        showLogin:0,
        showRegistro:0,
        selectedCursoInscripcion:{},
        carritoCursos:[],

        anio:'',
        mes:'',

        loadingFile:{},
        
    }
    
  },
  // could also be defined as
  // state: () => ({ count: 0 })
  actions: {
    getUsername() {
      return this.userName;
    },
    setUsername(newUserName) {
        this.userName=newUserName;
    },
    
  },
});