<template>
  <div class="CartaCurso col225 colSm90 row" style="margin: 15px;font-size:1rem;">
    
    <div class=" block tCenter glass" style="width:100%;line-height:40px;margin-top:10px;">
      
      <div class="orange fullWidth" style="width:100%;" v-if="cursoData.c5=='EN LÍNEA'">
        {{cursoData.c5}}
      </div>
      <div class="primary fullWidth" style="width:100%;" v-if="cursoData.c5=='PRESENCIAL'">
        {{cursoData.c5}}
      </div>

      <div class="fullWidth block tCenter" 
      style="margin:0 auto;overflow:hidden;">
        <img :src="cursoData.c2" style="object-fit:cover;height:130px;width:250px;">
      </div>

      <div class="col90 block tCenter" style="margin:0 auto;margin-top:0px;">
        <div>
          <h4 style="color:var(--blueDark);margin-bottom:0px;margin-top:0px;">
            {{cursoData.c1}}
          </h4>          
        </div>        
        <div>
          <label style="color:gray;line-height:5px;">
            <strong>
              ${{cursoData.c21}}
              <span 
              style="              
              color:black;
              display:block;
              position:relative;
              left:0;
              top:-30px;
              right:0;
              z-index:200;
              ">
                __________
              </span>
            </strong>
          </label>
          <label style="color:black;line-height:5px;">
            ${{cursoData.c11}}
          </label>
        </div>
        <div style="line-height:1;margin-bottom:5px;">

          <!--
            <small style="color:gray;">
              <i class="bi bi-person-vcard"></i>{{cursoData.c10}}
            </small>
          -->

        </div>
        <div>
          <div class="btn btnOrange col95 row" @click="setSelected()">
            <i class="bi bi-file-earmark-text-fill"></i>Ver Curso
          </div>

          

          <!--
          <div class="btn btnDefault col48 row" @click="userStore.carritoCursos[userStore.carritoCursos.length]=cursoData;">
            <i class="bi bi-cart-plus-fill"></i>
            <small>
              Agregar al Carrito
            </small>
          </div>
          <div class="btn btnBlue col95 row" @click="setSelectedInscripcion()">
            <i class="bi bi-pencil-square"></i>Inscribirme
          </div>
          -->
        </div>

      </div>

      <!--
      <div class="formRow col90 center glass tCenter">
    
        <div class="formRow colSm95" v-if="procesando==0">
          
          <button class="btn primary btnWide" 
          >Registrar</button>
                
        </div>
        
      </div>
      -->

    </div>

    <modal title="Oferta de Cursos" v-if="showModal" v-on:closeModalEv="closeModal()" style="display:block;left:10vw;top:10vh;height:30vh;">              
      <div style="text-align:center;overflow-y:auto;">
        <h3 style="color:black;">

          {{modalMessage}}
          
        </h3>
        
      </div>
      
    </modal>


  </div>
</template>

<script>

import { mapStores } from 'pinia';
import { useUserStore } from '../../store/user';
import Modal from '../Modal/Modal.vue';


export default {
  name: 'CartaCurso',
  components:{Modal},
  props:{
    cursoData:Object
  },
  data:function(){
    return{
      showModal:false,
      modalMessage:'',
      
    }
  },
  methods:{

    setSelected(){

      this.$emit('setSelectedCursoEv');


    }


  },
  computed:{
    ...mapStores(useUserStore),
  }
  
  

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@keyframes jello {
  11.1% {
    transform: none
  }
  22.2% {
    transform: skewX(-5.5deg) skewY(-5.5deg)
  }
  33.3% {
    transform: skewX(4.25deg) skewY(4.25deg)
  }
  44.4% {
    transform: skewX(-2.525deg) skewY(-2.525deg)
  }
  55.5% {
    transform: skewX(1.3625deg) skewY(1.3625deg)
  }
  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg)
  }
  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg)
  }
  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg)
  }
  100% {
    transform: none
  }
}


@keyframes jello2 {
  11.1% {
    transform: none
  }
  22.2% {
    transform: skewX(-5.5deg) skewY(-5.5deg)
  }
  
  33.4% {
    transform: skewX(-2.525deg) skewY(-2.525deg)
  }
  55.5% {
    transform: skewX(1.3625deg) skewY(1.3625deg)
  }
  
  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg)
  }
  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg)
  }
  100% {
    transform: none
  }
}


.center {
  display: inline-block
}

.jello {
  animation: jello 1.5s ease;
  transform-origin: center
}

.CartaCurso{
  color:black;
  transition: all 0.5s ease;
  transform: scale(0.96);
}

.CartaCurso:hover {
  transform: scale(1);
  animation: jello2 0.6s ease-in;
  transform-origin: center;
  
  
}


</style>
