import {createRouter,createWebHashHistory} from "vue-router";

import Home from './components/Registro/Home.vue';
import Login from './components/Login.vue';
/*
import NewUser from './components/NewUser.vue';
*/
import RegistroCuentaAlumno from './components/Registro/RegistroCuentaAlumno.vue';
import OfertaCursos from './components/Registro/OfertaCursos.vue';
import NotFound from './components/NotFound.vue';
import PoliticaBecas from './components/PoliticaBecas.vue';




const routes = [
    { path: '/', component: OfertaCursos },        
    { path: '/home', component: Home },
    { path: '/inicio', component: Login},
    { path: '/nuevoAlumno', component: RegistroCuentaAlumno},
    { path: '/programaBecas', component: PoliticaBecas},
    
    {
      path: "/:catchAll(.*)",
      component: NotFound,
    },
  ];

const router = createRouter({    
    history: createWebHashHistory(),
    routes,    
});

export default router;