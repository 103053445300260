<template>
  <div class="fullWidth" style="color:black;">
    
    <div class="block tCenter" style="width:100%;line-height:40px;margin-top:30px;" v-if="showNuevoRegistro==false">
      
      <div class="col95 tCenter" style="margin:0 auto;min-height: 20px;">

        <h2 class="center" style="font-family: town;color:var(--orange);"  v-if="userStore.carritoCursos.length==0">
          <strong style="text-shadow: 4px 4x 14px rgba(31,38,135,0.8);">
            REGISTRO DE ALUMNOS
          </strong>
        </h2>

      </div>

      <div class="formRow col90 center glass1" style="margin-top:10px;">

        <div class="tCenter" v-if="!confirmado&&userStore.carritoCursos.length>0">
        
          <div class="fullWidth">
            <div class="col80 row">
              <strong class="title" style="color:black;">
                Confirma tu Solicitud:
              </strong>
              <p>
                <label>
                  Te inscribiras a los siguientes 
                  <strong>
                    {{userStore.carritoCursos.length}}
                  </strong>
                   cursos:
                </label>
              </p>
            </div>
            <div class="col20 row">

              <button class="btnBlue" @click="confirmado=true;">
                <i class="bi bi-check2-square"></i>
                Confirmar
              </button>
              <button class="btnErrorDark" @click="$root.changeRoute('/')">
                <i class="bi bi-x-lg"></i>
                Cancelar
              </button>

            </div>
          </div>

          <div class="fullWidth tCenter" style="max-height:60vh;overflow-y:auto;">
            <div class="glassModulo col80 row center" v-for="(curso,cursoI) in userStore.carritoCursos" :key="curso" style="margin:10px;">

              <section>
                <div class="col30 row">
                  
                  <strong>
                    {{cursoI+1}})
                  </strong>

                  <img :src="curso.c2" class="col70">
                </div>
                <div class="col70 row tLeft">
                  <div class="col60 row">
                    <div>
                      <i style="color:var(--blueDark);">
                        {{curso.c1}}
                      </i>
                    </div>
                    <div>
                      <strong>
                        {{curso.horario.c16}}
                      </strong>
                    </div>
                    <div>
                      Del {{fechaToStr(curso.horario.c17)}} al {{fechaToStr(curso.horario.c18)}}
                    </div>
                  </div>

                  <div class="col40 row">

                    <div class="hideMobile">
                      <div v-if="curso.horario.c1!=''">
                        Lunes: 
                        <strong>
                          {{curso.horario.c1}} - {{curso.horario.c2}}
                        </strong>
                      </div>
                      <div v-if="curso.horario.c3!=''">
                        Martes: 
                        <strong>
                          {{curso.horario.c3}} - {{curso.horario.c4}}
                        </strong>
                      </div>
                      <div v-if="curso.horario.c5!=''">
                        Miercoles:
                        <strong>
                          {{curso.horario.c5}} - {{curso.horario.c6}}
                        </strong>
                      </div>
                      <div v-if="curso.horario.c7!=''">
                        Jueves:
                        <strong>
                          {{curso.horario.c7}} - {{curso.horario.c8}}
                        </strong>
                      </div>
                      <div v-if="curso.horario.c9!=''">
                        Viernes:
                        <strong>
                          {{curso.horario.c9}} - {{curso.horario.c10}}
                        </strong>
                      </div>
                      <div v-if="curso.horario.c11!=''">
                        Sábado:
                        <strong>
                          {{curso.horario.c11}} - {{curso.horario.c12}}
                        </strong>
                      </div>
                      <div v-if="curso.horario.c13!=''">
                        Domingo:
                        <strong>
                          {{curso.horario.c13}} - {{curso.horario.c14}}
                        </strong>
                      </div>
                    </div>

                    <div class="hideDesktop colSm95">
                      <div class="col48 row">
                        <div v-if="curso.horario.c1!=''">
                          Lunes: 
                          <strong>
                          {{curso.horario.c1}} - {{curso.horario.c2}}
                        </strong>
                        </div>
                        <div v-if="curso.horario.c3!=''">
                          Martes: 
                          <strong>
                            {{curso.horario.c3}} - {{curso.horario.c4}}
                          </strong>
                        </div>
                        <div v-if="curso.horario.c5!=''">
                          Miercoles:
                          <strong>
                            {{curso.horario.c5}} - {{curso.horario.c6}}
                          </strong>
                        </div>
                        <div v-if="curso.horario.c7!=''">
                          Jueves:
                          <strong>
                            {{curso.horario.c7}} - {{curso.horario.c8}}
                          </strong>
                        </div>
                      </div>
                      <div class="col48 row" style="margin-left:5px;">
                        <div v-if="curso.horario.c9!=''">
                          Viernes:
                          <strong>
                            {{curso.horario.c9}} - {{curso.horario.c10}}
                          </strong>
                        </div>
                        <div v-if="curso.horario.c11!=''">
                          Sábado:
                          <strong>
                            {{curso.horario.c11}} - {{curso.horario.c12}}
                          </strong>
                        </div>
                        <div v-if="curso.horario.c13!=''">
                          Domingo:
                          <strong>
                            {{curso.horario.c13}} - {{curso.horario.c14}}
                          </strong>
                        </div>
                      </div>
                    </div>

                  </div>


                </div>

                


              </section>
              
            </div>

            <div style="min-height:200px;">
              <span></span>
            </div>

          </div>

        </div>
        <div  v-if="confirmado||userStore.carritoCursos.length==0">

          <div v-if="userStore.carritoCursos.length>0">
            <div class="col80 row">
              <strong class="title" style="color:black;">
                Ya casi terminas
              </strong>
              <p>
                <label>
                  Para continuar inicia sesión o crea una cuenta
                </label>
              </p>
            </div>
            
            

          </div>

          <div class="col49 colSm95 row tLeft" style="" v-if="!showYaTengoCuenta">
            <div class="formRow col95 colSm95" >
                
              <div>
                
                <div class="col50 row">
                  <img src="img/nuevoUsuario.png" class="col50 center" style="vertical-align:bottom;">
                </div>
                <div class="col30 row">
                  <h3 style="font-family:town;vertical-align:bottom;color:var(--orange);line-height:50px;">
                    No estoy registrado
                  </h3>
                </div>

              </div>

              
              <div class="col950 block center tCenter" style="margin-top:20px;" >
                <button class="btnBlue col95" @click="showModalPrivacidad=true;">
                  <i class="bi bi-pencil-square"></i>
                  Crear Nueva Cuenta de Alumno
                </button>

                <button class="col95 btnOrange" @click="showYaTengoCuenta=true;">
                  Ya tengo cuenta
                </button>

              </div>
                
            </div>
          </div>

        
          <div class="col49 colSm95 row tLeft" v-if="showYaTengoCuenta">
            <div class="formRow colSm95">
                  
              <div class="formCol col95 colSm95 tCenter">

                <div>
                  <div class="col50 row center">
                    <img src="img/usuario.png" class="col50 row center" style="vertical-align:middle;">
                  </div>
                  <div class="col30 row center">

                    <h3 style="font-family: town;vertical-align:middle;color:var(--orange);">
                      Ya Soy Alumno Registrado
                    </h3>

                  </div>

                </div>

                <div class="formRow colSm95">
                  <label class="formLabel ">        
                    Correo Electrónico:
                  </label>
                  <span style="min-width:5%;width:5%;"></span>
                  <input-text ref="correoRef" :propName="'correo'"  :whiteBackground="false" :mode="'email'" 
                  :placeholder="'Correo Electrónico'" :capital="false" :maxLength="120" :minLength="5"></input-text>
                </div>
              </div>

              <div class="formCol col95 colSm95">
                <div class="formRow colSm95">
                  <label class="formLabel ">
                    Contraseña:
                  </label>
                  <span style="min-width:5%;width:5%;"></span>
                  <input-password ref="passwordRef" :propName="'password'"  :whiteBackground="false" :mode="'AN'" 
                  :placeholder="'Contraseña'" :capital="false" :maxLength="12" :minLength="8"></input-password>
                </div>
              </div>
              
              <div class="formCol col95 colSm95">
                <button class="btnBlue col95 center block" v-on:click="sendLogin()"                
                >
                  <i class="bi bi-pencil-square"></i>
                  Iniciar Sesión
                </button>

                <button class="col95 btnOrange center block" @click="showYaTengoCuenta=false;">
                  Crear cuenta nueva
                </button>

              </div>

                                  
            </div>
          </div>
        
        </div>
        
      </div>

    </div>
    
    <!--
      Nuevo Alumno
    -->
    
    <div class="block tCenter" style="width:100%;line-height:40px;margin-top:30px;" v-if="showNuevoRegistro">
      
      <div class="col50 block center tCenter" style="margin-top:20px;">
        <h2 style="font-family: town;color:var(--orange);" @dblclick="showVals=!showVals;">
          <strong style="text-shadow: 4px 4x 14px rgba(31,38,135,0.8);">
            REGISTRO DE NUEVA CUENTA DE ALUMNO
          </strong>
        </h2>
      </div>

      <div class="formRow col90 glass1 tLeft" style="overflow-y:auto;max-height:75vh;">
    
        <!--
        <div class="col98 colSm95 center tLeft" style="background:none;">
          <div class="col93 colSm95 center" >
              <strong class="formLabel">        
                Plantel:
              </strong>
              <span style="min-width:5%;width:5%;"></span>
              
              <input-select ref="plantelRef" :optionName="'Plantel'"  :whiteBackground="false" 
              :optionsArray="listaPlanteles" :propName="'plantel'"
              ></input-select>
                                  
          </div>
        </div>
        -->

        <div class="col98 center tCenter" style="background:none;margin-top:10px;">

          <div class="formCol col48 colSm95 ">
            <div class="formRow colSm95">
                  
              <strong class="formLabel " >        
                Nombre de usuario:
              </strong>
              <span style="min-width:5%;width:5%;"></span>
              <input-text ref="userNameRef" :placeholder="'Nombre de Usuario'" :propName="'userName'"  :whiteBackground="false"  :mode="'AN'" 
              :capital="false" :maxLength="35" :minLength="5"></input-text>
                                  
            </div>
          </div>

          <div class="formCol col48 colSm95">
            <div class="formRow colSm95">
                
              <strong class="formLabel " >        
                Correo Electrónico:
              </strong>
              <span style="min-width:5%;width:5%;"></span>          
              
              <input-text ref="correoRef" :propName="'correo'"  :whiteBackground="false" :mode="'email'" 
              :placeholder="'Correo Electrónico'" :capital="false" :maxLength="120" :minLength="5"></input-text>

            </div>
          </div>


          <div class="formCol col48 colSm95 ">
            <div class="formRow colSm95">
                  
              <strong class="formLabel " >
                Contraseña:
              </strong>
              <span style="min-width:5%;width:5%;"></span>
              <input-text ref="passwordRef" :placeholder="'Contraseña'" :propName="'password'"  :whiteBackground="false"  :mode="'AN'" 
              :capital="false" :maxLength="12" :minLength="8"></input-text>
                                  
            </div>
          </div>


          <div class="formCol col48 colSm95">
            <div class="formRow colSm95">
                
              <strong class="formLabel " >        
                Teléfono de Contacto:
              </strong>
              <span style="min-width:5%;width:5%;"></span>
              <input-text ref="telefonoRef" :propName="'telefono'" :whiteBackground="false" :mode="'N'" 
              :placeholder="'Teléfono de Contacto'" :capital="true" :maxLength="10" :minLength="10"></input-text>
                            
            </div>
          </div>

          <div class="formCol col48 colSm95">
            <div class="formRow colSm95">
                
              <strong class="formLabel " >        
                Relación con Grupo Chedraui:
              </strong>
              <span style="min-width:5%;width:5%;"></span>
              <input-select ref="relacionChedrauiRef" :optionName="'Tipo de relación con Grupo Chedraui'"  :whiteBackground="false" 
              :optionsArray="listaRelacionChedraui" :propName="'relacionChedraui'" :valFieldName="'c2'" 
              ></input-select>
                            
            </div>
          </div>

          <div class="formCol col48 colSm95">
            <div class="formRow colSm95">
                  
              <strong class="formLabel ">        
                CURP:
              </strong>
              <span style="min-width:5%;width:5%;"></span>
              <input-text ref="curpRef" :placeholder="'CURP'" :propName="'curp'"  :whiteBackground="false"  :mode="'curp'" 
              :capital="true" :maxLength="18" :minLength="18"></input-text>
                          
            </div>
          </div>

        </div>

        <div class="col98 center tCenter" style="background:none;">
          
          <div class="formCol col48 colSm95">
            <div class="formRow colSm95">
              <strong class="formLabel " >        
                  Nombre(s):
              </strong>
              <span style="min-width:5%;width:5%;"></span>            
              <input-text ref="nombreRef" :propName="'nombre'"  :whiteBackground="false" :mode="'A'"   
              :capital="true" :maxLength="120" :minLength="2" :placeholder="'Nombre(s)'"></input-text>
            </div>
          </div>

          <div class="formCol col48 colSm95">
            <div class="formRow colSm95">
              
              <strong class="formLabel " >        
                Primer Apellido:
              </strong>
              <span style="min-width:5%;width:5%;"></span>
              <input-text ref="ap1Ref" :capital="true" :propName="'ap1'" :whiteBackground="false" :mode="'A'" 
              :maxLength="120" :minLength="2" :placeholder="'Primer Apellido'"></input-text>
              
            </div>
          </div>                    
              
          <div class="formCol col48 colSm95">
            <div class="formRow colSm95">
                  
              <strong class="formLabel " >        
                Segundo Apellido:
              </strong>
              <span style="min-width:5%;width:5%;"></span>        
              <input-text ref="ap2Ref" :propName="'ap2'"  :whiteBackground="false"  :mode="'A'" 
              :capital="true" :maxLength="120" :minLength="2" :placeholder="'Segundo Apellido'"></input-text>
                          
            </div>          
          </div>

          <div class="formCol col48 colSm95">
            <div class="formRow colSm95">
              <span></span>                            
            </div>
          </div>

        </div>

        <div v-if="showVals">
            {{
              'NombreUsuario:'+(this.formData.userName.state==1)+'|'+            
              'Correo:'+(this.formData.correo.state==1)+'|'+
              'Contraseña:'+(this.formData.password.state==1)+'|'+
              'Telefono:'+(this.formData.telefono.state==1)+'|'+
              'Relación Chedraui:'+(this.formData.relacionChedraui.state==1)+'|'+
              'CURP:'+(this.formData.curp.state==1)+'|'+
              'Nombre:'+(this.formData.nombre.state==1)+'|'+
              'Ap1:'+(this.formData.ap1.state==1)+'|'+
              'Ap2:'+(this.formData.ap2.state==1)
            }}
          </div>


        <div class="formRow colSm95" v-if="procesando==0">
          
          <button class="btnDefault col50 hideMobile" v-on:click="showNuevoRegistro=false;">
            <i class="bi bi-arrow-left"></i>
            Regresar
          </button>

          <button class="btnBlue col50 colSm95" v-on:click="sendRegistrar()"
          v-if="isRegistroCompleted()"
          >
            <i class="bi bi-pencil-square"></i>
            Registrar Cuenta de Alumno
          </button>

          <button class="btnDefault col50 colSm95" v-on:click="mostrarMensajeRegistrar()" v-else>
            <i class="bi bi-pencil-square"></i>
            Registrar Cuenta de Alumno
          </button>
          
          <button class="btnDefault hideDesktop colSm95" v-on:click="showNuevoRegistro=false;">
            <i class="bi bi-arrow-left"></i>
            Regresar
          </button>

          <div style="min-height:80px;">
            <span>
            </span>
          </div>
          
        </div>
        <div class="formRow colSm95" v-if="procesando==1">
          <div>
            <h3>
              Procesando Registro...
            </h3>
          </div>                  
          <div>
            <h4>
              Espere por favor
            </h4>
          </div>        
        </div>
      </div>

    </div>

    <modal title="Aviso de Privacidad" v-if="showModalPrivacidad" v-on:closeModalEv="closeModalPrivacidad()" style="display:block;left:10vw;top:10vh;height:80vh;">
      

      <div style="text-align:center;overflow-y:auto;height:50vh;">

        <object data="https://www.sitioliceo.com/assets/privacidad.pdf" type="application/pdf" width="100%" height="100%" style="height:50vh;">
        </object>
        
      </div>

      <div>

        <div style="text-align:center;overflow-y:auto;height:10vh;">
          <strong>
            Para continuar lee y acepta el aviso de privacidad
          </strong>
        </div>

        <button class="btnBlue col50 row" @click="showModalPrivacidad=false;showNuevoRegistro=true;">
          Aceptar
        </button>

        <button class="btnDefault col50 row" @click="showModalPrivacidad=false;">
          No Aceptar
        </button>

      </div>
    </modal>

    <modal title="Registro" v-if="showModal" v-on:closeModalEv="closeModal()" style="display:block;left:10vw;top:10vh;height:30vh;">
      <div style="text-align:center;overflow-y:auto;">
        <h3 style="color:black;">
          {{modalMessage}}
        </h3>
      </div>
      <div class="fullWidth" v-if="registroExitoso">

        <div class="col95 center tCenter titleBlack">
          <p>
            Nombre de Usuario:
            <strong>
              {{formData.userName.data}}
            </strong>
          </p>
          <p>
              Correo:
            <strong>
              {{formData.correo.data}}
            </strong>
          </p>
          <p>            
            Contraseña:
            <strong>
              {{formData.password.data}}
            </strong>
          </p>
        </div>
        <div class="fullWidth">
          <button class="btnBlue col95 center" @click="$root.changeRoute('/inicio')">
            Continuar a Iniciar Sesión
          </button>
        </div>


      </div>

    </modal>

  </div>
</template>

<script>

import { mapStores } from 'pinia';
import { useUserStore } from '../../store/user';
import InputText from '../Inputs/InputText.vue';
import InputPassword from '../Inputs/InputPassword.vue';
import InputSelect from '../Inputs/InputSelect.vue';
import Modal from '../Modal/Modal.vue';


export default {
  name: 'RegistroCuentaAlumno',
  components:{
    Modal,
    InputText,
    InputSelect,
    InputPassword
  },
  mounted:function(){

    this.confirmado=false;
    this.userStore.currentView='REGISTRO';

  },
  data:function(){
    return{

      registroExitoso:false,
      showVals:false,
      showYaTengoCuenta:false,
      alumnoData:{},
      confirmado:false,
      showModalPrivacidad:false,
      showModal:false,
      showNuevoRegistro:false,
      modalMessage:'',
      procesando:0,
      modalidad:'',
      listaPlanteles:[{c1:'XALAPA'},{c1:'VERACRUZ'},{c1:'VILLAHERMOSA'}],
      listaModalidad:[{c0:'ID',c1:'PRESENCIAL'},{c0:'ID',c1:'EN LÍNEA'}],
      listaDuracion:[{c0:'ID',c1:'SESIÓN UNICA'},{c0:'ID',c1:'SEMANAL'},{c0:'ID',c1:'MENSUAL'},{c0:'ID',c1:'TRIMESTRAL'},{c0:'ID',c1:'SEMESTRAL'}],
      listaCursos:['Maquillaje básico','Manejo del Portal SAT','Educación Financiera'],
      listaHorarios:[],
      listaRelacionChedraui:[{c1:'EXTERNO',c2:'E'},{c1:'COLABORADOR',c2:'C'},{c1:'FAMILIAR DE COLABORADOR',c2:'F'}],
      formData:{
        'password':{data:"",state:0},
        'modalidad':{data:"",state:0},
        'duracion':{data:"",state:0},
        'plantel':{data:"",state:0},
        'carrera':{data:"",state:0,optional:true},
        'correo':{data:"",state:0},
        'telefono':{data:"",state:0},
        'nombre':{data:"",state:0},
        'ap1':{data:"",state:0},
        'ap2':{data:"",state:0},
        'curp':{data:"",state:0},        
        'userName':{data:"",state:0},        
        'horario':{data:"",state:0},        
        'estado':{data:"",state:0},        
        'municipio':{data:"",state:0},        
        'secundaria':{data:"",state:0},        
        'promedio':{data:"",state:0},
        'relacionChedraui':{data:"",state:0},
      },
    };    
  },
  methods:{

    mostrarMensajeRegistrar(){

      this.showModal=true;
      this.modalMessage="Debes completar el formulario para continuar."

    },

    fechaToStr(fechaStr){

      let anio = ''+fechaStr[0]+fechaStr[1]+fechaStr[2]+fechaStr[3];
      let meses = {
        '1':'Enero',
        '2':'Febrero',
        '3':'Marzo',
        '4':'Abril',
        '5':'Mayo',
        '6':'Junio',
        '7':'Julio',
        '8':'Agosto',
        '9':'Septiembre',
        '10':'Octubre',
        '11':'Noviembre',
        '12':'Diciembre'
      };
      let mesN = parseInt(''+fechaStr[5]+fechaStr[6]);

      let mes = ''+meses[''+mesN];

      let dia = ''+fechaStr[8]+fechaStr[9];

      return ''+dia+' de '+mes+' de '+anio;

    },

    closeModal(){
      this.showModal = false;      
    },
    closeModalPrivacidad(){
      this.showModalPrivacidad = false;
    },
    isRegistroCompleted(){
      return (
          this.formData.userName.state==1
          &&this.formData.correo.state==1
          &&this.formData.password.state==1
          &&this.formData.telefono.state==1
          &&this.formData.relacionChedraui.state==1
          &&this.formData.curp.state==1
          &&this.formData.nombre.state==1
          &&this.formData.ap1.state==1
          &&this.formData.ap2.state==1
        );
      
    },
    sendRegistrar(){
      console.log("sendRegistrar()");
      let listaCursos = [];
      let l = this.userStore.carritoCursos.length;
      for(let i=0;i<l;i++){
        listaCursos[listaCursos.length] = ''+this.userStore.carritoCursos[i].horario.c0;
      }
      let listaCursosStr=JSON.stringify(listaCursos);
      //let passw = this.$root.genPSW(8);

      
      let data = {
        nombreUsuario:''+this.formData.userName.data,
        telefono:''+this.formData.telefono.data,
        correo:''+this.formData.correo.data,
        tipoRel:''+this.formData.relacionChedraui.data,
        curp:''+this.formData.curp.data,
        nombre:''+this.formData.nombre.data,
        ap1:''+this.formData.ap1.data,
        ap2:''+this.formData.ap2.data,
        password:''+this.formData.password.data,
        listaCursos:''+listaCursosStr
      };
      console.log("data: ");
      console.log(data);
      this.$root.sendPOSTRequest(
        "https://www.sitioliceo.com/scLCcur/api/superSC/v1/alumnos/nuevoRegistroAlumno/",
        data,
        this.onSuccessSendRegistrar
      );
    },
    onSuccessSendRegistrar(data){
      console.log("onSuccessSendRegistrar:");
      console.log(data);
      
      this.showModal=true;
      if(data.insert!=='ok'){
        this.modalMessage=''+data.insert;
      }else{
        if(this.userStore.carritoCursos.length==0){
          this.modalMessage='Registro de cuenta exitoso. Para continuar inicia sesión.';
          this.registroExitoso=true;
        }else{
          this.modalMessage='Registro de solicitudes de inscripción exitoso';
          this.registroExitoso=true;
        }
      }
    },

    sendLogin(){
      console.log("sendLogin()");
      //let passw = this.$root.genPSW(8);
      let data = {
        correo:''+this.formData.correo.data,        
        psw:''+this.formData.password.data,        
      };
      console.log("data: ");
      console.log(data);
      this.$root.sendPOSTRequest(
        "https://www.sitioliceo.com/scLCcur/api/superSC/v1/alumnos/getAlumnoData/",
        data,
        this.onSuccessSendLogin
      );
    },
    onSuccessSendLogin(data){
      console.log("onSuccessSendLogin:");
      console.log(data.data);

      if(data.data!==null){
        //this.$root.importData(data)
        this.alumnoData=data.data;
        this.sendRegistrarCursos(''+this.formData.correo.data);        
      }

      //this.$root.importData(data);
    },

    sendRegistrarCursos(correo){
      console.log("sendRegistrarCursos()");
      let listaCursos = [];
      let l = this.userStore.carritoCursos.length;
      for(let i=0;i<l;i++){
        listaCursos[listaCursos.length] = ''+this.userStore.carritoCursos[i].horario.c0;
      }
      let listaCursosStr=JSON.stringify(listaCursos);
      //let passw = this.$root.genPSW(8);
      let data = {
        correo:''+correo,
        listaCursos:''+listaCursosStr
      };
      console.log("data: ");
      console.log(data);
      this.$root.sendPOSTRequest(
        "https://www.sitioliceo.com/scLCcur/api/superSC/v1/alumnos/nuevaInscripcionAlumnoEnCurso/",
        data,
        this.onSuccessSendRegistrarCursos
      );
    },
    onSuccessSendRegistrarCursos(data){
      console.log("onSuccessSendRegistrarCursos:");
      console.log(data);
      if(data.nInserts==0){
        this.showModal=true;
        let registroMsg = data.insertsEstadoInscripcion.join();
        this.modalMessage=""+registroMsg;
      }else{
        this.showModal=true;
        this.modalMessage='Registro de solicitudes de inscripción exitoso';
        this.$root.importData(this.alumnoData);
      }
      //this.$root.importData(data);      
    },




  },
  computed:{
    ...mapStores(useUserStore),
    
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.glassModulo{
    
    z-index:100;  
    
    background: rgba(255,255,255,0.30);    
    backdrop-filter: blur(2px);
    box-shadow: 0 10px 32px 0 rgba(31,38,135,0.37);    
    margin: 0 auto;
    margin-bottom: 0px;
    border-radius: 10px;
    padding:5px;
    border: 1px solid rgba(255,255,255,0.18);
    
  }

</style>
