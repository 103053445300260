<template>
  <div class="navBar blueOriginal" style="position:fixed;top:0;">
    <div class="fullWidth" style="background:none;">

      <div class="col27 row" @click="toHome()" style="background:red;">
        <img class="iconBrand" src="./../../../public/img/logoObscuro.png" style="margin-top:-5px;position:absolute;left:0;z-index:100;">
      </div>
      
      <div class="col35 row tLeft" style="background:none;">
        <div class="fullWidth block tCenter" style="background:none;">
          <h3 class="navTitle" style="margin-bottom:3px;margin-top:10px;">
            Portal de Cursos 
          </h3>
        </div>
        <div class="block tCenter" style="padding-bottom:10px;">
          <h3 class="navTitle" style="margin-top:3px;">
            &nbsp;LAO    
          </h3>
        </div>

      </div>

      

      <div class="col38 row tRight" style="background:yellow;min-height:50px;margin-top:10px;background:none;padding-right: 1%;">

        <button class="btnBlueDark" @click="toPoliticaBecas()">
          <i class="bi bi-book-fill" style="font-size:200%;"></i>
          <small class="hideMobile">
            Programa de Becas
          </small>
        </button>
        
        <button class="btnBlueDark" @click="optionMode=(optionMode==''?'CARRITO':'');">
          <i class="bi bi-cart-fill" style="font-size:200%;"></i>
          <strong style="background:orange;border-radius:50px;padding:3px;">
            <span>
            {{userStore.carritoCursos.length}}
            </span>
          </strong>
          <small class="hideMobile">
            Carrito
          </small>
        </button>

        
        
        <button class="btnBlueDark"  v-if="userStore.login==1" @click="optionMode=(optionMode==''?'MENU':'');">
          <i class="bi bi-list" style="font-size:200%;"></i>
        </button>
        

        <button class="btnBlueDark"  v-if="userStore.login==0"  @click="toLogin()">
          <!--
            <i class="bi bi-list" style="font-size:200%;"></i>
            -->
          <i class="bi bi-key-fill" style="font-size:200%;"></i>
          <small class="hideMobile">
            Iniciar Sesión
          </small>
        </button>

        <!--
          @click="optionMode=(optionMode==''?'LOGOUT':'');" 
          -->

        <button class="btnBlueDark"  v-if="userStore.login==0" @click="toNuevoRegistro()">
          <i class="bi bi-pencil-square" style="font-size:200%;"></i>
          <small class="hideMobile">
            Registrar Cuenta
          </small>
        </button>

        <div 
        class="col25 colSm95 glass" 
        style=" 
        text-align:left;
        position:fixed;
        top:7%;
        right:0%;
        z-index:200;        
        min-height:20vh;
        background:white;
        color:black;
        " 
        v-if="optionMode=='CARRITO'||optionMode=='MENU'"
        >
          <div v-if="optionMode=='CARRITO'" style="max-height:30vh;padding:4px;">

            <div style="max-height:28vh;">
              <div>
                Carrito de Cursos:
                <button class="btn right" @click="optionMode='';">
                  <i class="bi bi-x-lg"></i>
                </button>
              </div>
              <div class="fullWidth" style="min-height:20vh;max-height:22vh;overflow-y:auto;">
                <div class="glassCarrito" style="margin-top:20px;border-radius:5px;padding:3px;" v-for="(curso,cI) in userStore.carritoCursos" :key="curso">
                  <strong>
                    {{cI+1}} ) {{curso.c1}} <i class="bi bi-trash-fill right" style="cursor:pointer;" @click="eliminarCursoDeCarrito(curso)"></i>
                  </strong>
                </div>
                <div style="min-height:100px;">
                  <span></span>
                </div>
              </div>
              <div class="fullWidth">
                <button class="btnBlue fullWidth" @click="optionMode='';$root.changeRoute('nuevoAlumno');">
                  Inscribir a cursos en carrito
                </button>
              </div>
            </div>

          </div>

          <div v-if="optionMode=='MENU'">
            <div>
              Menú:
            </div>
            <!--
            <div class="glassCarrito" style="cursor:pointer;border-radius:5px;padding:3px;margin-top:5px;">
              <i class="bi bi-key-fill"></i>
              Cambiar Contraseña
            </div>
            -->
            <div class="glassCarrito" style="cursor:pointer;border-radius:5px;padding:3px;margin-top:5px;" @click="optionMode='';toHome();">
              <i class="bi bi-door-closed-fill"></i>
              Cerrar Sesión
            </div>
          </div>
          

        </div>
        
        
      </div>
      
      
      
    </div>
  </div>
</template>

<script>
  
  import { mapStores } from 'pinia'
  import { useUserStore } from '../../store/user';
  


  export default {
  
    name: 'Navbar',
    props: {
      msg: String
    },
    data:function(){

      return{
        optionMode:''


      }

    },
    methods:{

      toPoliticaBecas(){
        this.$root.changeRoute("/programaBecas");
      },

      toNuevoRegistro(){

        this.$root.changeRoute("/nuevoAlumno");

      },

      eliminarCursoDeCarrito(curso){

        let l = this.userStore.carritoCursos.length;

        for(let i=0;i<l;i++){

          if(this.userStore.carritoCursos[i].c0==curso.c0){

            this.userStore.carritoCursos.splice(i,1);

          }

        }

      },

      toAlumnoHome(){
        if(this.userStore.plantel==''){

          this.userStore.plantel='XALAPA';
        }
        this.$root.changeRoute("/home");
      },

      toLogin(){
        if(this.userStore.plantel==''){

          this.userStore.plantel='XALAPA';
        }
        this.$root.changeRoute("/inicio");
      },

      toHome(){
        this.userStore.login=0;
        this.$root.changeRoute("/");
      }

    },
    computed:{
    
    ...mapStores(useUserStore)

    },
    
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.glassCarrito{
    
    background: rgba(255,255,255,0.30);    
    backdrop-filter: blur(2px);
    box-shadow: 0 8px 32px 0 rgba(31,38,135,0.37);
    
    margin: 0 auto;
    border-radius: 10px;
    padding:10px;    
    border: 1px solid rgba(255,255,255,0.18);
  
  }


</style>
