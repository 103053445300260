<template>
  <div class="fullWidth" style="padding-top:0px;">
    
    <div class="fullWidth" style="margin-top:0px;">

      <div :class="'fullWidth tCenter '+(plantel==''?'from0toFull':'fromFullto0')" style="vertical-align:middle;padding-bottom:0px;border-bottom: solid 10px var(--orange);margin-top:0px;overflow:hidden;">
        <div class="fullWidth colSm50 row rowm tCenter"  style="vertical-align:top;height:32vh;">
          
          <div class="slider col50 colSm95 center" style="max-height: 35vh;margin-top:-15px;">
            <img :src="getPromoImage()" class="col80 colSm95">
            <!--
            <img src="https://webdevworks.xyz/liceo/portalcursos/img/promo8.png" class="col43 colSm90">
            <img src="https://webdevworks.xyz/liceo/portalcursos/img/promo9.png" class="col43 colSm90">
            -->
            <div class="col50 center" style="background:none;">
              <button style="background:var(--white);border:none;border-radius:50px;padding:8px;margin:3px;" @click="imgIndex=imgIndex>0?imgIndex-1:listaPromos.length-1;">
                <span>
                </span>
              </button>
              <button style="background:var(--white);border:none;border-radius:50px;padding:8px;margin:3px;" @click="imgIndex=0;">
                <span>
                </span>
              </button>
              <button style="background:var(--white);border:none;border-radius:50px;padding:8px;margin:3px;" @click="imgIndex=imgIndex<(listaPromos.length-1)?imgIndex+1:0;">
                <span>
                </span>
              </button>
            </div>
          </div>
          
          
          <!--
          <img src="img/chica2.png" class="col43 colSm90" style="margin-bottom:20px;">
          -->
        </div>
      </div>


      <div class="formRow tCenter" 
      style="background-color: white;width:100%;border-top: solid 15px var(--orange);min-height:200vh;">

        <div>

          <div class="col50 block center orange" style="margin-top:0px;overflow-y:auto;">

            <img class="fullWidth center block" src="./../../../public/img/horizontalprincipalbeca.png" style="cursor:pointer;" @click="toPoliticaBecas()">

          </div>            

          <div class="block" style="margin-top:20px;" v-if="mode==1">

            <div>
              <h2 style="font-family: town;color:var(--orange);">
                <strong style="text-shadow: 4px 4x 14px rgba(31,38,135,0.8);">
                  SELECCIONA UN PLANTEL
                </strong>
              </h2>
            </div>
            <div class="col30 row" >
              <div class="btn btnXalapa" @click="setPlantel('XALAPA')">
                <div class="grayOverlay" style="padding:15%;line-height:7vh;">
                  <span></span>
                </div>
              </div>
              <div style="margin-top:10px;">
                <label class="subtitleSize" style="font-family:reey;color:var(--blue);">
                  Xalapa
                </label>
              </div>
            </div>


            <div class="col30 row" >
              <div class="btn btnVeracruz" @click="setPlantel('VERACRUZ')">
                <div class="grayOverlay" style="padding:15%;line-height:7vh;">
                  <span></span>
                </div>
              </div>
              <div style="margin-top:10px;">
                <label class="subtitleSize" style="font-family:reey;color:var(--blue);">
                  Veracruz
                </label>
              </div>
            </div>

            


            <div class="col30 row" >
              <div class="btn btnVillahermosa" @click="setPlantel('VILLAHERMOSA')">
                <div class="grayOverlay" style="padding:15%;line-height:7vh;">
                  <span></span>
                </div>
              </div>
              <div style="margin-top:10px;">
                <label class="subtitleSize" style="font-family:reey;color:var(--blue);">
                  Villahermosa
                </label>
              </div>
            </div>


            
          </div>

          
          
          <div class="col70 colSm90 center" v-if="mode==2" style="min-height:80vh;">
            
            <div>

              <select class="inputSelect" v-model="categoria">
                <option selected disabled>
                  Selecciona una categoría
                </option>
                <option v-for="categoria in listaCategorias" :key="categoria" :value="categoria.c2">
                  {{categoria.c0}}
                </option>
              </select>

            </div>

          <!--
            <div>
              <h2 style="font-family: town;color:var(--orange);">
                <strong >
                  SELECCIONA UNA CATEGORÍA
                </strong>
              </h2>
            </div>

            

            <div class="center tRight" >

              <div class="col95 center tCenter">

                <div class="btn col225 colSm30 row rowm  btnBelleza" @click="setCategoria('BELLEZA')"  style="margin:3px;" v-if="listaPlanteles[''+plantel].categorias.belleza">
                  <div class="grayOverlay" style="padding:15%;color:transparent;">
                    BELLEZA
                  </div>
                </div>
                <div class="btn col225 colSm30 row rowm btnContabilidad " @click="setCategoria('CONTABILIDAD')" style="margin:3px;" v-if="listaPlanteles[''+plantel].categorias.contabilidad">
                  <div class="grayOverlay" style="padding:15%;">            
                    <small style="color:white;color:transparent;">                
                      CONTABILIDAD
                    </small>
                  </div>
                </div>
                <div class="btn col225 colSm30 row rowm btnGastronomia " @click="setCategoria('GASTRONOMÍA')" style="margin:3px;" v-if="listaPlanteles[''+plantel].categorias.gastronomia">
                  <div class="grayOverlay" style="padding:15%;color:transparent;">            
                    GASTRONOMÍA
                  </div>            
                </div>
                <div class="btn col225 colSm30 row rowm btnModas " @click="setCategoria('DISEÑO DE MODAS')" style="margin:3px;" v-if="listaPlanteles[''+plantel].categorias.modas">
                  <div class="grayOverlay" style="padding:15%;color:transparent;"> 
                  <small>
                    MODAS
                  </small>           
                  </div>
                </div>
                <div class="btn col225 colSm30 row rowm btnBienestar " @click="setCategoria('BIENESTAR')" style="margin:3px;"  v-if="listaPlanteles[''+plantel].categorias.bienestar">
                  <div class="grayOverlay" style="padding:15%;">            
                    <label style="color:transparent;">
                      BIENESTAR
                    </label>
                  </div>
                </div>
                <div class="btn col225 colSm30 row rowm btnCiencias " @click="setCategoria('CIENCIAS')" style="margin:3px;" v-if="listaPlanteles[''+plantel].categorias.ciencias">
                  <div class="grayOverlay" style="padding:15%;color:transparent;">            
                    CIENCIAS
                  </div>
                </div>
                <div class="btn col225 colSm30 row rowm btnAdmision " @click="setCategoria('ADMISIÓN')" style="margin:3px;" v-if="listaPlanteles[''+plantel].categorias.examen">
                  <div class="grayOverlay" style="padding:15%;padding-left:5%;padding-right:5%;">            
                    <small style="color:transparent;">                  
                      examen
                    </small>
                  </div>
                </div>
                <div class="btn col225 colSm30 row rowm btnIdiomas " @click="setCategoria('IDIOMAS')" style="margin:3px;" v-if="listaPlanteles[''+plantel].categorias.idiomas">
                  <div class="grayOverlay" style="padding:15%;color:transparent;">            
                    IDIOMAS
                  </div>
                </div>
                <div class="btn col225 colSm30 row rowm btnDiseno " @click="setCategoria('DISEÑO')" style="margin:3px;" v-if="listaPlanteles[''+plantel].categorias.diseno">
                  <div class="grayOverlay" style="padding:15%;color:transparent;">            
                    DISEÑO
                  </div>
                </div>
                <div class="btn col225 colSm30 row rowm btnInformatica " @click="setCategoria('INFORMÁTICA')" style="margin:3px;" v-if="listaPlanteles[''+plantel].categorias.informatica">
                  <div class="grayOverlay" style="padding:15%;color:transparent;">            
                    INFORMÁTICA
                  </div>
                </div>

                <div class="btn col225 colSm30 row rowm btnCuidadoPersonal " @click="setCategoria('CUIDADO PERSONAL')" style="margin:3px;" v-if="listaPlanteles[''+plantel].categorias.cuidadoPersonal">
                  <div class="grayOverlay" style="padding:15%;color:transparent;">            
                    CUIDADO
                  </div>
                </div>


                <div class="btn col225 colSm30 row rowm btnMatematicas " @click="setCategoria('MATEMATICAS')" style="margin:3px;"  v-if="listaPlanteles[''+plantel].categorias.matematicas">
                  <div class="grayOverlay" style="padding:15%;color:transparent;">            
                    MATEMÁTICAS
                  </div>
                </div>

                <div class="btn col225 colSm30 row rowm btnManualidades " @click="setCategoria('MANUALIDADES')" style="margin:3px;"  v-if="listaPlanteles[''+plantel].categorias.manualidades">
                  <div class="grayOverlay" style="padding:15%;color:transparent;">            
                    MANUALIDADES
                  </div>
                </div>

                <div class="btn col225 colSm30 row rowm btnSustentabilidad " @click="setCategoria('SUSTENTABILIDAD')" style="margin:3px;" v-if="listaPlanteles[''+plantel].categorias.sustentabilidad">
                  <div class="grayOverlay" style="padding:15%;">            
                    <label style="color:transparent;">
                      SUSTENTABILIDAD
                    </label>
                  </div>
                </div>

              </div>

              <div class="tCenter center">
                <div class="btnBlue col858 colSm30 row rowm tCenter" @click="setCategoria('')">
                  <small>
                    TODAS LAS CATEGORÍAS
                  </small>                          
                </div>
              </div>
              

            </div>
            
          -->
          </div>
          <!--
          <div v-if="mode==3" style="height:80vh;">
            <div>
              <h2 style="font-family: town;color:var(--orange);">
              <strong >
                SELECCIONA LA MODALIDAD
              </strong>
            </h2>
            </div>
            <div class="btn col30 row btnPresencial" @click="setModalidad('PRESENCIAL')" style="margin:5px;">
              <div style="padding:15%;">
                PRESENCIAL
              </div>
            </div>
            <div class="btn col30 row btnLinea" @click="setModalidad('EN LÍNEA')" style="margin:5px;">
              <div style="padding:15%;">
                EN LÍNEA
              </div>
            </div>          
          </div>
          -->

          <div class="col80 colSm90 center tCenter" v-if="mode==4" style="text-align:left;height:80vh;">
            <div class="tCenter">
              <h2 style="font-family: town;color:var(--orange);">
                <strong >
                  SELECCIONA LA DURACIÓN
                </strong>
              </h2>
            </div>
            <div 
            v-for="(periodoDur) in listaDuracion" 
            :key="periodoDur"           
            :class="'btn col30 row btnBlue'" 
            @click="setDuracion(periodoDur.c1)"
            style="margin:5px;"
            >
              <div>
                <div style="padding:12%;font-size:1.3rem;text-align:center;">
                  <small>
                    {{periodoDur.c1}}
                  </small>
                </div>
              </div>
            </div>
            
          </div>
        </div>


        <div class="col95 center tCenter" v-if="plantel!=''" style="background-color: white;width:100%;">

          <div>
            <h2 style="font-family: town;color:var(--orange);">
              <strong >
                SELECCIONA UN CURSO
              </strong>
            </h2>
          </div>

          <div class="center">
            <div class="fullWidth tCenter row" style="vertical-align:top;">

              <div class="col40 row tRight">
                <div class="col85 row" style="">
                  <input class="input" type="text" v-model="busqueda" style="line-height: 25px;background:none;" placeholder="Buscar Curso">
                </div>
                <div class="col5 row tCenter" style="background:var(--blue);border-top-right-radius: 5px;border-bottom-right-radius: 5px;line-height: 29px;">
                  <i class="bi bi-search"></i>
                </div>
              </div>
            
              <div class="col60 row tLeft" style="padding-left:10px;">
                <!--
                <button class="btnBlue" @click="mode=1;plantel='';">
                  Plantel
                  <small style="color:var(--orange);">
                    {{plantel}}
                  </small>
                </button>
                -->
                <div class="col30 row">
                  
                  <div class="col95 center">
                    <select class="inputSelect" v-model="plantel" style="line-height:31px;">
                      <option selected disabled>
                        Selecciona el plantel
                      </option>
                      <option>
                        XALAPA
                      </option>
                      <option>
                        VERACRUZ
                      </option>
                      <option>
                        VILLAHERMOSA
                      </option>
                    </select>                  
                  </div>
                  
                </div>
                <!--
                <button class="btnBlue" @click="mode=2;">
                  Categoría 
                  <small style="color:var(--orange);">
                    {{categoria}}
                  </small>
                </button>
                -->
                <div class="col30 row" >

                  <div class="col95 center">
                    <select class="inputSelect" v-model="categoria"  style="line-height: 31px;">
                      <option selected disabled :value="''">
                        Selecciona una categoría
                      </option>
                      <option v-for="categoria in listaCategorias" :key="categoria">
                        {{(''+categoria.c0).toUpperCase()}}
                      </option>
                    </select>
                  </div>

                </div>

<!--
                <div class="col30 row">
                  <div class="col95 center">
                    <select class="inputSelect" v-model="modalidad">
                      <option selected disabled>
                        Selecciona la modalidad
                      </option>
                      <option>
                        PRESENCIAL
                      </option>
                      <option>
                        EN LÍNEA
                      </option>                    
                    </select>
                  </div>
                </div>
-->
                <!--
                {{categoria}}
                <button class="btnBlue" @click="getListaCursos()">
                  Cargar Lista de Cursos
                </button>
                -->

              </div>


              <div class="tCenter" style="margin-top:10px;">
                <div :class="'col30 row '+(listaMode=='ACTUALES'?'btnTabSelected':'btnTabUnselected')" @click="listaMode='ACTUALES'" style="min-height:4vh;">
                  <label style="font-family: town;margin:3px;">
                    <strong style="text-shadow: 4px 4x 14px rgba(31,38,135,0.8);">
                      CURSOS ACTUALES
                    </strong>
                  </label>
                </div>

                <div :class="'col30 row '+(listaMode=='ANTERIORES'?'btnTabSelected':'btnTabUnselected')" @click="listaMode='ANTERIORES'" style="min-height:4vh;">
                  <label style="font-family: town;margin:3px;">
                    <strong style="text-shadow: 4px 4x 14px rgba(31,38,135,0.8);">
                      CURSOS ANTERIORES
                    </strong>
                  </label>
                </div>

                <div  :class="'col30 row '+(listaMode=='PROXIMOS'?'btnTabSelected':'btnTabUnselected')" @click="listaMode='PROXIMOS'" style="min-height:4vh;">
                  <label style="font-family: town;margin:3px;">
                    <strong style="text-shadow: 4px 4x 14px rgba(31,38,135,0.8);">
                      PRÓXIMOS CURSOS
                    </strong>
                  </label>
                </div>
              </div>


            </div>
            
          </div>

          <div style="text-align:left;max-height:60vh;overflow-y:auto;overflow-x:hidden;" v-if="listaMode=='ACTUALES'">
              
              <CartaCurso v-for="curso in filtroCursos" :key="curso" :cursoData="curso" @setSelectedCursoEv="setSelectedCurso(curso)">
              </CartaCurso>
            
          </div>

          <div style="text-align:left;max-height:60vh;overflow-y:auto;overflow-x:hidden;"  v-if="listaMode=='ANTERIORES'">
              
              <CartaCurso v-for="curso in listaAnteriores" :key="curso" :cursoData="curso" @setSelectedCursoEv="setSelectedCurso(curso)">
              </CartaCurso>
            
          </div>

          <div style="text-align:left;max-height:60vh;overflow-y:auto;overflow-x:hidden;" v-if="listaMode=='PROXIMOS'">
              
              <CartaCurso v-for="curso in listaProximos" :key="curso" :cursoData="curso" @setSelectedCursoEv="setSelectedCurso(curso)">
              </CartaCurso>
            
          </div>
          
        </div>

      </div>

<!--
      <div class="col90 center tLeft" style="font-size:1.3rem;background-color: white;width:100%;">

        <div class="row" @click="plantel='';mode=1;">
          Plantel:
          <small style="color:var(--orange);">
            {{plantel}}
          </small>
          &nbsp;
          <i class="bi bi-chevron-down" style="cursor:pointer;"></i>
          &nbsp;
        </div>        

        <div class="row" @click="categoria='';mode=2;">
          Categoría:
          <small style="color:var(--orange);">
            {{categoria}}
          </small>
          &nbsp;
          <i class="bi bi-chevron-down" style="cursor:pointer;"></i>
          &nbsp;
        </div>

        <div class="row" @click="modalidad='';mode=3;">
          Modalidad:
          <small style="color:var(--orange);">
            {{modalidad}}
          </small>
          &nbsp;
          <i class="bi bi-chevron-down" style="cursor:pointer;"></i>
          &nbsp;
        </div>

        <div class="row" @click="duracion='';mode=4;">
          Duración:
          <small style="color:var(--orange);">
            {{duracion}}
          </small>
          &nbsp;
          <i class="bi bi-chevron-down" style="cursor:pointer;"></i>
          &nbsp;
        </div>

      </div>
-->

        
      

    </div>

    <modal :title="modalTitle" v-if="showModal" v-on:closeModalEv="closeModal()" style="display:block;left:10vw;top:10vh;height:30vh;">
      <div style="text-align:center;overflow-y:auto;">
        <h3 style="color:black;">

          {{modalMessage}}
          
        </h3>

        <div class="col80 block center orange" v-if="showBannerBecas" style="margin-top:-30px;overflow-y:auto;">

          <img class="fullWidth center block" src="./../../../public/img/bannerBecas.png" style="cursor:pointer;" @click="toPoliticaBecas()">

        </div>  
        
      </div>      
    </modal>

    <VistaCurso :cursoID="userStore.selectedCursoInscripcion.c0" v-if="showVistaCurso" @cerrarVistaCursoEv="showVistaCurso=false;">
    </VistaCurso>
  </div>
</template>

<script>

import { mapStores } from 'pinia';
import { useUserStore } from '../../store/user';
/*
import InputText from '.https://webdevworks.xyz/liceo/portalcursos/Inputs/InputText.vue';
import InputSelect from '.https://webdevworks.xyz/liceo/portalcursos/Inputs/InputSelect.vue';
*/

//import InputText from '../Inputs/InputText.vue';
import Modal from '../Modal/Modal.vue';
import CartaCurso from './CartaCurso.vue';
import VistaCurso from './VistaCurso.vue';
//import {data} from './cursos';

export default {
  name: 'OfertaCursos',
  components:{
    Modal,
    CartaCurso,
    VistaCurso,
    //InputText
  },
  watch:{  

    listaMode:function(){


      if(this.listaMode=='ACTUALES'){

        this.getListaCursos();
        
      }

      if(this.listaMode=='ANTERIORES'){

        this.getListaCursosAnteriores();
        
      }

      if(this.listaMode=='PROXIMOS'){

        this.getListaCursosProximos();
        
      }

    },

    plantel:function(){

      this.getListaCursos();

    },
    
    categoria:function(){

        this.setCategoria(this.categoria);

    },

    modalidad:function(){

      this.getListaCursos();

    }
    

  },
  mounted:function(){

    this.userStore.currentView='OFERTA';
    
    //this.listaCursos=data.getData();
    //this.listaHorarios=data.getHorariosData();
    console.log(this.listaCursos);
    this.getListaPromos();

    this.getListaPlanteles();
    this.getCurrentVersion();
    
    
    

    this.userStore.principalView=true;
    //this.userStore.login=1;

  },
  data:function(){
    return{

      currentVersion:'',
      verMsg:'',
      listaMode:'ACTUALES',
      imgSrc:'',
      imgIndex:0,
      mode:1,
      busqueda:'',
      modalTitle:'',
      showVistaCurso:false,
      showModal:false,
      showBannerBecas:false,
      modalMessage:'',
      plantel:'',
      categoria:'',
      modalidad:'PRESENCIAL',
      duracion:'',
      nCursos:0,
      cursoSeleccionado:{},
      listaPromos:[],
      listaNombrePlanteles:[
      ],
      listaPlanteles:{
        'XALAPA':{
          categorias:{
            belleza:true,
            bienestar:true,
            ciencias:true,
            contabilidad:true,
            cuidadoPersonal:true,
            examen:true,
            diseno:true,
            gastronomia:true,
            idiomas:true,
            informatica:true,
            modas:true,
            manualidades:true,
            matematicas:false,
            sutentabilidad:false
          }
        },
        'VERACRUZ':{
          categorias:{
            belleza:true,
            bienestar:true,
            ciencias:true,
            contabilidad:true,
            cuidadoPersonal:true,
            examen:true,
            diseno:true,
            gastronomia:true,
            idiomas:true,
            informatica:true,
            modas:true,
            manualidades:true,
            matematicas:true,
            sutentabilidad:true
          }
        },
        'VILLAHERMOSA':{
          categorias:{
            belleza:true,
            bienestar:true,
            ciencias:true,
            contabilidad:true,
            cuidadoPersonal:true,
            examen:true,
            diseno:true,
            gastronomia:true,
            idiomas:true,
            informatica:true,
            modas:true,
            manualidades:true,
            matematicas:true,
            sutentabilidad:true
          }
        }
      },
      listaModalidad:[{c0:'ID',c1:'PRESENCIAL'},{c0:'ID',c1:'EN LÍNEA'}],
      listaDuracion:[
        {c0:'ID',c1:'1 SESIÓN'},
        {c0:'ID',c1:'2 SESIONES'},        
        {c0:'ID',c1:'1 MES'},
        {c0:'ID',c1:'2 MESES'},
        {c0:'ID',c1:'3 MESES'},
        {c0:'ID',c1:'4 MESES'},
        {c0:'ID',c1:'5 MESES'},
        {c0:'ID',c1:'6 MESES'},
        {c0:'ID',c1:'TODO'}
      ],
      listaCursos:[],
      listaAnteriores:[],
      listaProximos:[],
      listaHorarios:[],
      listaCategorias:[

        {c0:'Belleza',                                  c1:{xalapa:false,veracruz:false,villahermosa:false},c2:'BELLEZA'},
        {c0:'Bienestar',                                c1:{xalapa:false,veracruz:false,villahermosa:false},c2:'BIENESTRA'},
        //{c0:'Ciencias',                                 c1:{xalapa:false,veracruz:false,villahermosa:false},c2:'CIENCIAS'},
        {c0:'Contabilidad',                             c1:{xalapa:false,veracruz:false,villahermosa:false},c2:'CONTABILIDAD'},
        //{c0:'Cuidado Personal',                         c1:{xalapa:false,veracruz:false,villahermosa:false},c2:'CUIDADOPERSONAL'},
        //{c0:'Preparación a Examen de Admisión',         c1:{xalapa:false,veracruz:false,villahermosa:false},c2:'EXAMEN'},
        {c0:'Diseño Grafico',                           c1:{xalapa:false,veracruz:false,villahermosa:false},c2:'DISENO'},
        {c0:'Preparación de Alimentos',                 c1:{xalapa:false,veracruz:false,villahermosa:false},c2:'GASTRONOMIA'},
        {c0:'Idiomas',                                  c1:{xalapa:false,veracruz:false,villahermosa:false},c2:'IDIOMAS'},
        {c0:'Informática',                              c1:{xalapa:false,veracruz:false,villahermosa:false},c2:'INFORMATICA'},
        {c0:'Confección de Prendas',                    c1:{xalapa:false,veracruz:false,villahermosa:false},c2:'MODAS'},
        {c0:'Manualidades',                             c1:{xalapa:false,veracruz:false,villahermosa:false},c2:'MANUALIDADES'},
        //{c0:'Matemáticas',                              c1:{xalapa:false,veracruz:false,villahermosa:false},c2:'MATEMATICAS'},
        {c0:'Sutentabilidad',                           c1:{xalapa:false,veracruz:false,villahermosa:false},c2:'SUSTENTABILIDAD'},
        {c0:'Campos Formativos',                           c1:{xalapa:true,veracruz:true,villahermosa:true},c2:'FORMACION'},
        //{c0:'Redacción de Textos Laborales y Escolares',c1:{xalapa:false,veracruz:false,villahermosa:false},c2:'REDACCION'},
        {c0:'Todas las categorias',                     c1:{xalapa:true ,veracruz:true ,villahermosa:true },c2:''},

      ]

    }
  },
  methods:{

    toPoliticaBecas(){
      this.$root.changeRoute("/programaBecas");
    },

    closeModal(){

      this.showModal = false;

    },

    showCheckBecasMsg(){
      console.log("showCheckBecasMsg()");

      if(localStorage.getItem('estado')!=null){
        console.log("localStorage.estado");
        let estadoLoaded=JSON.parse(localStorage.estado);

        if(!estadoLoaded.mostrarMensajeBecas){
          console.log("!estadoLoaded.mostrarMensajeBecas");
          this.showModal=true;
          this.modalTitle='Aviso';
          this.showBannerBecas=true;

          let estado={
                
            version:""+estadoLoaded.version,
            actualizado:true,
            mostrarMensajeBecas:true
    
          };
    
          localStorage.estado=JSON.stringify(estado);

        }

      }

    },

    getCurrentVersion(){

      let data={};

      console.log("data:");
      console.log(data);
      
      this.$root.sendPOSTRequest(
      "https://www.sitioliceo.com/scLCcur/api/superSC/v1/alumnos/verificarVersionPortalCursos/",
      data,
      this.onSuccessVersion,
      this.onErrorVersion);

    },
    onSuccessVersion(data){

      console.log(data);
      
      this.userStore.showLogin=(data.acceso=='1');
      this.userStore.showRegistro=(data.registro=='1');
      this.setVersionState(data.version);

    },
    onErrorVersion(error){
      console.log(error);

    },

    setVersionState(currentVersion){
      console.log('currentVersion:');
      console.log(currentVersion);
      let version=currentVersion;

        //localStorage.clear();
        if(localStorage.estado){
            let estadoLoaded=JSON.parse(localStorage.estado);
            //this.version=estadoLoaded.version;
            //this.versionActual=version;
            //alert("Version actual: "+estadoLoaded.version);
            
            //console.log("Estado:");
            //console.log(localStorage.estado);
            //console.log("estadoLoaded.version="+estadoLoaded.version);
            //console.log("currentVersion="+currentVersion);

            if(estadoLoaded.version!=version){
              let estado={
                
                version:""+version,
                actualizado:true,
                mostrarMensajeBecas:estadoLoaded.mostrarMensajeBecas
              };
              
              localStorage.estado=JSON.stringify(estado);
              //this.userData.version=currentVersion;
              //alert("Estado Actualizado");
              this.verMsg="Versión Actualizada: "+this.currentVersion;
              console.log("Versión Actualizada");
              //alert("Versión Actualizada");
              console.log(localStorage.estado);
              location.reload(true);
            }
            this.currentVersion=currentVersion;

        }else{
          let estado={            
            version:""+currentVersion,
            actualizado:true,
            mostrarMensajeBecas:false
          };
          //this.userData.version=currentVersion;
          localStorage.estado=JSON.stringify(estado);
          //alert("Estado Guardado");
          this.verMsg="Versión guardada";
          console.log(localStorage.estado);
          this.currentVersion=currentVersion;
        }

        this.showCheckBecasMsg();

    },

    getListaCursosAnteriores(){
      console.log("getListaCursos()");
      let data={
        'plantel':''+this.plantel,
        'modalidad':''+this.modalidad,
        'categoria':''+this.categoria
      };
      this.$root.sendPOSTRequest(
        "https://www.sitioliceo.com/scLCcur/api/superSC/v1/alumnos/getListaCursosAnteriores/",
        data,
        this.onSuccessGetListaCursosAnteriores
      );
    },

    onSuccessGetListaCursosAnteriores(data){
      console.log("onSuccessGetListaCursosAnteriores:");
      console.log(data.data);
      this.setListaCursosAnteriores(data);
    },    
    setListaCursosAnteriores(data){
      
      this.listaAnteriores = data.data.data;
  
    },

    getListaCursosProximos(){
      console.log("getListaCursos()");
      let data={
        'plantel':''+this.plantel,
        'modalidad':''+this.modalidad,
        'categoria':''+this.categoria
      };
      this.$root.sendPOSTRequest(
        "https://www.sitioliceo.com/scLCcur/api/superSC/v1/alumnos/getListaCursosProximos/",
        data,
        this.onSuccessGetListaCursosProximos
      );
    },
    onSuccessGetListaCursosProximos(data){
      console.log("onSuccessGetListaCursosProximos:");
      console.log(data.data);
      this.setListaCursosProximos(data);
    },
    setListaCursosProximos(data){
      
      this.listaProximos = data.data.data;
  
    },

    getListaCursos(){
      console.log("getListaCursos()");
      let data={
        'plantel':''+this.plantel,
        'modalidad':''+this.modalidad,
        'categoria':''+this.categoria
      };
      this.$root.sendPOSTRequest(
        "https://www.sitioliceo.com/scLCcur/api/superSC/v1/alumnos/getListaCursos/",
        data,
        this.onSuccessGetListaCursos
      );
    },
    onSuccessGetListaCursos(data){
      console.log("onSuccessGetListaCursos:");
      console.log(data.data);
      this.setListaCursos(data);
    },    
    setListaCursos(data){
      
      this.listaCursos = data.data.data;
      //this.listaHorarios= data.listaHorarios.data;
      console.log("listaCursos:");
      console.log(this.listaCursos);
  /*
      console.log("listaHorarios setListaCursos:");
      console.log(data.listaHorarios.data);
  */    
    },


    getListaPlanteles(){

      let data={};
      this.$root.sendPOSTRequest(
        "https://www.sitioliceo.com/scLCcur/api/superSC/v1/alumnos/getCatalogoPlanteles/",
        data,
        this.onSuccessGetListaPlanteles(data)
      );

    },
    onSuccessGetListaPlanteles(data){
      console.log("onSuccessGetListaPlanteles:");
      console.log(data);      
      this.setListaPlanteles(data);

    },    
    setListaPlanteles(data){
      
      this.listaNombrePlanteles=data;      

    },

    getPromoImage(){

      return this.listaPromos.length>0?this.listaPromos[this.imgIndex]:'img/chica2.png';

    },    

    getListaPromos(){

      let data=[
        'https://webdevworks.xyz/liceo/portalcursos/img/promo8.png',
        'https://webdevworks.xyz/liceo/portalcursos/img/promo9.png',
      ];

      this.onSuccessGetListaPromos(data);

    },
    onSuccessGetListaPromos(data){
      console.log("onSuccessGetListaPromos:");
      console.log(data);      
      this.setListaPromos(data);

    },
    onErrorGetListaPromos(data){

      console.log("Error:");
      console.log(data);

    },
    setListaPromos(data){
      let l=data.length;

      this.listaPromos[0]='https://webdevworks.xyz/liceo/portalcursos/img/chica2.png';
      for(let i=0;i<l;i++){

        this.listaPromos[this.listaPromos.length]=data[i];

      }
      this.imgIndex=0;
      this.imgSrc=this.listaPromos[this.imgIndex];
      console.log("ListaPromos:");
      console.log(this.listaPromos);

    },

    setSelectedCurso(curso){

      this.userStore.selectedCursoInscripcion=curso;
      this.showVistaCurso=true;

    },

    setModalidad(modalidad){
      this.modalidad=modalidad;
      this.mode=0;
    },

    setPlantel(plantel){
      this.plantel=plantel;
      this.userStore.plantel=plantel;
      //window.scrollTo(0,100);
      this.mode=0;
    },
    setCategoria(categoria){
      this.categoria=categoria;
      this.mode=0;
      this.getListaCursos();
      //alert("Categoría: "+categoria);
      //console.log("setCategoria()");
    },
    setDuracion(duracion){
      this.duracion=duracion;
      this.mode=0;
    },

    getBtnColorClass(index){

      let i=(index)%6+1;
      
      let style;
      switch(i){

        case 1:
          style=`
          gradientColor1
          `;
        break;

        case 2:
          style=`
          gradientColor2
          `;
        break;

        case 3:
          style=`
          gradientColor3
          `;
        break;

        case 4:
          style=`
          gradientColor4
          `;
        break;

        case 5:
          style=`
          gradientColor5
          `;
        break;

        case 6:
          style=`
          gradientColor6
          `;
        break;
        

      }

      console.log("gradiente: "+i);
      return style;

    },

    filtrarListaPorPlantel(data,plantel){
      let arr=[];
      let l=data.length;
      for(let i=0;i<l;i++){
        if(data[i].c3==plantel){
          arr[arr.length]=data[i];
        }
      }
      return arr;
    },
    filtrarListaPorCategoria(data,categoria){
      let arr=[];
      let l=data.length;
      for(let i=0;i<l;i++){
        if(data[i].c4==categoria){
          arr[arr.length]=data[i];
        }
      }
      return arr;
    },
    filtrarListaPorModalidad(data,modalidad){
      let arr=[];
      let l=data.length;
      for(let i=0;i<l;i++){
        if(data[i].c5==modalidad){
          arr[arr.length]=data[i];
        }
      }
      return arr;
    },
    filtrarListaPorDuracion(data,duracion){
      let arr=[];
      let l=data.length;
      for(let i=0;i<l;i++){
        if(data[i].c18==duracion){
          arr[arr.length]=data[i];
        }
      }
      return arr;
    },

    filtrarListaPorBusqueda(data,busqueda){
    let arr=[];
      let l=data.length;
      for(let i=0;i<l;i++){
        //console.log("buscando en:");
        //console.log(data[i].c1);
        //data[i].c1.toUpperCase().indexOf(busqueda.toUpperCase())!=-1
        if(this.containsSubstring(data[i].c1,busqueda)){
          arr[arr.length]=data[i];
        }
        
      }
      return arr;
    },
    containsSubstring(strOriginal,searchingStr){

      let strOrig = ''+strOriginal.toLowerCase();
      let searchStr = ''+searchingStr.toLowerCase();

      strOrig = strOrig.replace(/á/g, "a");
      strOrig = strOrig.replace(/é/g, "é");
      strOrig = strOrig.replace(/í/g, "i");
      strOrig = strOrig.replace(/ó/g, "o");
      strOrig = strOrig.replace(/ú/g, "u");

      searchStr = searchStr.replace(/á/g, "a");
      searchStr = searchStr.replace(/é/g, "é");
      searchStr = searchStr.replace(/í/g, "i");
      searchStr = searchStr.replace(/ó/g, "o");
      searchStr = searchStr.replace(/ú/g, "u");

      return (strOrig.indexOf(searchStr)!=-1);


    },


  },
  computed:{
    ...mapStores(useUserStore),
    filtroCursos(){
      let arr=this.listaCursos;
      //console.log("Cursos:");
      //console.log(arr);

      /*
      if(this.plantel!=''){
        arr=this.filtrarListaPorPlantel(arr,this.plantel);
      }
      */
      //console.log("plantel:");
      //console.log(arr);

      /*
      if(this.categoria!=''){
        arr=this.filtrarListaPorCategoria(arr,this.categoria);
      }
      */
      //console.log("Categoría:");
      //console.log(arr);

      /*
      if(this.modalidad!=''){
        arr=this.filtrarListaPorModalidad(arr,this.modalidad);
      }
      */
      //console.log("Modalidad:");
      //console.log(arr);

      /*
      if(this.duracion!=''&&this.duracion!='TODO'){
        arr=this.filtrarListaPorDuracion(arr,this.duracion);
      }
      */
      //console.log("Duración:");
      //console.log(arr);

      if(this.busqueda!=''){
        console.log("Filtro por Busqueda");
        arr=this.filtrarListaPorBusqueda(arr,this.busqueda);
      }
      //console.log("Búsqueda:");
      //console.log(arr);

      //this.nCursos=arr.length;
      return arr;
    }
    
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .btnBelleza:hover,
  .btnContabilidad:hover,
  .btnGastronomia:hover,
  .btnModas:hover,
  .btnBienestar:hover,
  .btnCiencias:hover,
  .btnAdmision:hover,
  .btnIdiomas:hover,
  .btnDiseno:hover,
  .btnInformatica:hover,
  .btnSustentabilidad:hover,
  .btnPresencial:hover,
  .btnLinea:hover,
  .btnXalapa:hover,
  .btnVeracruz:hover,
  .btnVillahermosa:hover,
  .btnCalendario:hover
  {
    transform: scale(0.96);
  }
  .btnCalendario{
    color:white;
    overflow: hidden;
  }
  .calendarioOverlay{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/calendario.png') center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .btnPresencial{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/presencial.png') center;
    background-color: var(--blueOriginal); 
    color:transparent; 
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .btnLinea{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/linea.png') center;  
    background-color: var(--orange);  
    color:transparent;
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .btnXalapa{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/xalapa.png') center;  
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .btnVeracruz{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/veracruz.png') center;  
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .btnVillahermosa{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/villahermosa.png') center;  
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .btnBelleza{  
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/belleza.png') center;  
    background-color: var(--blue);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;

  }

  .btnContabilidad{      
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/contabilidad.png') center;  
    background-color: var(--blue);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }
  .btnGastronomia{  
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/gastronomia.png') center;  
    background-color: var(--blue);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .btnModas{  
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/modas.png') center;  
    background-color: var(--blue);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .btnBienestar{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/bienestar.png') center;  
    background-color: var(--blue);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .btnCiencias{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/ciencias.png') center;  
    background-color: var(--blue);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .btnAdmision{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/examen.png') center; 
    background-color: var(--blue);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .btnIdiomas{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/idiomas.png') center;  
    background-color: var(--blue);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .btnDiseno{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/disenio.png') center;  
    background-color: var(--blue);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .btnInformatica{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/informatica.png') center;  
    background-color: var(--blue);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }


  .btnMatematicas{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/matematicas.png') center;  
    background-color: var(--blue);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }


  .btnSustentabilidad{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/sustentabilidad.png') center;  
    background-color: var(--blue);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .btnCuidadoPersonal{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/cuidadoPersonal.png') center;  
    background-color: var(--blue);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .btnManualidades{
    background: url('https://webdevworks.xyz/liceo/portalcursos/img/manualidades.png') center;  
    background-color: var(--blue);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .btnTabSelected{

    
    background:white;
    border-top-left-radius:5px;
    border-top-right-radius:5px; 
    border-left:solid 1px lightgray;
    border-right: solid 2px gray;
    border-top: solid 1px lightgray;
    color:var(--orange);

  }

  .btnTabSelected:hover{

    
    background:lightgray;
    border-top-left-radius:5px;
    border-top-right-radius:5px; 
    border-left:solid 1px lightgray;
    border-right: solid 2px gray;
    border-top: solid 1px lightgray;
    color:var(--orange);

  }

  .btnTabUnselected{

    
    background:var(--blue);
    color:white;
    border-top-left-radius:5px;
    border-top-right-radius:5px; 
    border-left:solid 1px lightgray;
    border-right: solid 2px gray;
    border-top: solid 1px lightgray;
    color:white;

  }

  .btnTabUnselected:hover{

    
    background:var(--blueOriginal);
    color:white;
    border-top-left-radius:5px;
    border-top-right-radius:5px; 
    border-left:solid 1px lightgray;
    border-right: solid 2px gray;
    border-top: solid 1px lightgray;
    color:white;

  }

  

</style>
