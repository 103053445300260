<template>
  <div class="fullWidth white tCenter">
    <div>
      <span style="margin-top:50px;"></span>
    </div>
    <div>
      <h1 class="col50 block center" >
        Ruta no Encontrada
      </h1>
    </div>
    <div>
    <button class="btnBlue col50" @click="toHome()">
      Volver al Inicio
    </button>
    </div>

  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useUserStore } from '../store/user';

export default {
  name: 'NotFound',
  methods:{
    toHome(){
        this.userStore.login=0;
        this.$root.changeRoute("/");
      }
  },
  computed:{
    ...mapStores(useUserStore),
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
