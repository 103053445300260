<template>
  <div class="CartaCurso col95 colSm90 row" style="margin: 15px;font-size:1rem;">
    
    <div class=" block tCenter glassCard" style="width:100%;line-height:40px;margin-top:10px;">
      
      <div class="orange fullWidth" style="width:100%;" v-if="cursoData.c5=='EN LÍNEA'">
        {{cursoData.c5}}
      </div>
      <div class="primary fullWidth" style="width:100%;" v-if="cursoData.c5=='PRESENCIAL'">
        {{cursoData.c5}}
      </div>

      <div class="col20 row tCenter" 
      style="margin:0 auto;overflow:hidden;">
        <img :src="cursoData.c34" style="object-fit:cover;height:130px;width:250px;">
      </div>

      <div class="col75 row tLeft" style="margin:0 auto;margin-top:0px;">
        <div class="col70 row" style="min-height:25vh;">
          
          <div>
            <div>
              <h4 style="color:var(--blueDark);margin-bottom:0px;margin-top:0px;">
                {{cursoData.c2}}
              </h4>
            </div>
            <!--
            <div>
              <label style="color:black;">
                ${{cursoData.c11}}
              </label>
            </div>
            -->
            <div style="line-height:1;margin-bottom:5px;">
              <small style="color:gray;">
                <i class="bi bi-person-vcard"></i>{{cursoData.c35}}
              </small>
            </div>
          </div>
          <div>

            <div class="col95 center">              
              <div>
                Del {{$root.fechaToStr(''+cursoData.c20)}} al {{$root.fechaToStr(''+cursoData.c21)}}
              </div>

              <div class="col95 row">

                <div class="hideMobile">
                  <div v-if="cursoData.c5!=''">
                    Lunes: 
                    <strong>
                      {{cursoData.c5}} - {{cursoData.c6}}
                    </strong>
                  </div>
                  <div v-if="cursoData.c7!=''">
                    Martes: 
                    <strong>
                      {{cursoData.c7}} - {{cursoData.c8}}
                    </strong>
                  </div>
                  <div v-if="cursoData.c9!=''">
                    Miercoles:
                    <strong>
                      {{cursoData.c9}} - {{cursoData.c10}}
                    </strong>
                  </div>
                  <div v-if="cursoData.c11!=''">
                    Jueves:
                    <strong>
                      {{cursoData.c11}} - {{cursoData.c12}}
                    </strong>
                  </div>
                  <div v-if="cursoData.c13!=''">
                    Viernes:
                    <strong>
                      {{cursoData.c13}} - {{cursoData.c14}}
                    </strong>
                  </div>
                  <div v-if="cursoData.c15!=''">
                    Sábado:
                    <strong>
                      {{cursoData.c15}} - {{cursoData.c16}}
                    </strong>
                  </div>
                  <div v-if="cursoData.c17!=''">
                    Domingo:
                    <strong>
                      {{cursoData.c17}} - {{cursoData.c18}}
                    </strong>
                  </div>
                </div>

                <div class="hideDesktop colSm95">
                  <div class="col48 row">
                    <div v-if="cursoData.c5!=''">
                      Lunes: 
                      <strong>
                      {{cursoData.c5}} - {{cursoData.c6}}
                    </strong>
                    </div>
                    <div v-if="cursoData.c7!=''">
                      Martes: 
                      <strong>
                        {{cursoData.c7}} - {{cursoData.c8}}
                      </strong>
                    </div>
                    <div v-if="cursoData.c9!=''">
                      Miercoles:
                      <strong>
                        {{cursoData.c9}} - {{cursoData.c10}}
                      </strong>
                    </div>
                    <div v-if="cursoData.c11!=''">
                      Jueves:
                      <strong>
                        {{cursoData.c11}} - {{cursoData.c12}}
                      </strong>
                    </div>
                  </div>
                  <div class="col48 row" style="margin-left:5px;">
                    <div v-if="cursoData.c13!=''">
                      Viernes:
                      <strong>
                        {{cursoData.c13}} - {{cursoData.c14}}
                      </strong>
                    </div>
                    <div v-if="cursoData.c15!=''">
                      Sábado:
                      <strong>
                        {{cursoData.c15}} - {{cursoData.c16}}
                      </strong>
                    </div>
                    <div v-if="cursoData.c17!=''">
                      Domingo:
                      <strong>
                        {{cursoData.c17}} - {{cursoData.c18}}
                      </strong>
                    </div>
                  </div>
                </div>

                </div>

            </div>

          </div>

          
          <div style="display:flex;" v-if="false">

            

            <div class="btnWhite tCenter" style="flex-grow: 3;">
              <strong>
                <i class="bi bi-card-heading"></i>                
              </strong>

              <label class="tCenter" :for="'curp'+cursoData.c0" style="flex-grow: 3;cursor:pointer;" v-if="cargando==0">
                <b>
                  <i class="bi bi-upload"></i>Cargar CURP
                </b>
              </label>
              <input 
                class="form-control btn btn-primary"
                type="file" 
                :name="'curp'+cursoData.c0"
                :id="'curp'+cursoData.c0"
                :ref="'curp'+cursoData.c0+'Ref'" 
                v-on:change="handleFileDoc(userStore.idAlCur+'curp','.pdf','curp'+cursoData.c0)" 
                accept=".pdf"
                v-bind:value="fileName"
              />
              <small v-if="cargando==1">
                Cargando
              </small>

            </div>



            <div class="btnWhite tCenter" style="flex-grow: 3;">
              <strong>
                <i class="bi bi-currency-dollar"></i>
              </strong>
              <!--
              <small>
                <b>
                  Comprobante de aportación
                </b>
              </small>
              {{userStore.mes}}
              -->

              <label class="tCenter" :for="'comprobante'+cursoData.c0" style="flex-grow: 3;cursor:pointer;" v-if="cargando==0">
                <b>
                  <i class="bi bi-upload"></i>
                  Cargar Comprobante de aportación
                </b>
              </label>
              <input 
                class="form-control btn btn-primary"
                type="file" 
                :name="'comprobante'+cursoData.c0"
                :id="'comprobante'+cursoData.c0"
                :ref="'comprobante'+cursoData.c0+'Ref'" 
                v-on:change="handleFileDoc(cursoData.c0+'pago','.png','comprobante'+cursoData.c0)" 
                accept=".png"
                v-bind:value="fileName"
              />
              <small v-if="cargando==1">
                Cargando
              </small>

            </div>
            
            <div class="btnWhite tCenter"  style="flex-grow: 3;">
              <strong>
                <i class="bi bi-bag-heart-fill"></i>
              </strong>
              <small>
                <b>                      
                  Estado de Donativo
                </b>
              </small>
            </div>
            <div class="btnWhite tCenter"  style="flex-grow: 3;">
              <strong>
                <i class="bi bi-card-checklist"></i>
              </strong>
              <small>
                <b>
                  Registro de encuesta
                </b>
              </small>
            </div>


          </div>
          

        </div>
        <div class="col30 row">
          <div class="btn btnOrange col95 row" @click="setSelected()">
            <i class="bi bi-file-earmark-text-fill"></i>Ver Curso Inscrito
          </div>
          
          <div class="btn btnBlue col95 row" @click="getConstancia(cursoData.c36,(''+$root.fechaToStr($root.getTimeStamp())))" v-if="cursoData.c36!='-'">
            <i class="bi bi-download"></i>Descargar Constancia
          </div>

          <div class="btn btnDefault col95 row" v-if="cursoData.c36=='-'" @click="showMensajeConstanciaNoLiberada()">
            <i class="bi bi-download"></i>Descargar Constancia
          </div>

          

        </div>

      </div>

      <!--
      <div class="formRow col90 center glass tCenter">
    
        <div class="formRow colSm95" v-if="procesando==0">
          
          <button class="btn primary btnWide" 
          >Registrar</button>
                
        </div>
        
      </div>
      -->

    </div>



  
  </div>
</template>

<script>
/*


import { useUserStore } from '../../store/user';
import InputText from '../Inputs/InputText.vue';
import InputSelect from '../Inputs/InputSelect.vue';
*/
import { mapStores } from 'pinia';
import { useUserStore } from '../../store/user';
//import Modal from '../Modal/Modal.vue';
//import axios from 'axios';


export default {
  name: 'CartaCursoInscrito',
  components:{
    //Modal
  },
  props:{
    cursoData:Object
  },
  data:function(){
    return{
      showModal:false,
      modalMessage:'',
      cargando:0,
      comprobanteImgSrc:'',
      modalView:'MSG',
      modalTitle:'',
      file:null,
      fileName:'',
      
    }
  },
  methods:{


    showMensajeConstanciaNoLiberada(){

      let msgParam={
        title:'Constancia No Disponible',
        msg:'Podra descargar su constancia de participación desde este botón una vez concluido el curso.'
      };

      this.$emit('showMensajeEv',msgParam)
    },

   

    handleFileDoc(uploadingFileName,extension,fileInputId){

      this.userStore.loadingFile = {
        fileSrc:'',
        filename:''+uploadingFileName,
        extension:''+extension,
        folio:''+this.cursoData.c0,
        file:null
      };

      this.cargando=1;
      console.log("HandleFileDoc:");

      this.$forceUpdate();

      //console.log("Nombre Doc: cursoImagenUploadRef");
      console.log(this.$refs);
      console.log("IputRef:");
      console.log(this.$refs[''+fileInputId+'Ref']);
      console.log("InputRefValue:");
      console.log(this.$refs[''+fileInputId+'Ref'].value);

      console.log("Archivo: ");
      console.log(this.$refs[''+fileInputId+'Ref']);
      console.log(this.$refs[''+fileInputId+'Ref'].files);
      console.log(this.$refs[''+fileInputId+'Ref'].files[0]);

      let file = this.$refs[''+fileInputId+'Ref'].files[0];

      console.log("File:");
      console.log(file);

      console.log("FileSize:");
      console.log(file.size);

      let tamArchSeleccionado = (file.size/1048576);
      console.log("FileSizeMB:");
      console.log( tamArchSeleccionado );

      console.log("FileName:");
      console.log( file.name );
      let fileName=file.name;
      let ext=(fileName.substring(fileName.lastIndexOf("."))).toLowerCase();
      console.log(ext);

      let tamMaxPermitido = parseFloat('1.0');
      console.log("Tam en MB"+tamArchSeleccionado+"<"+tamMaxPermitido);

      if(extension==ext){

        if(tamArchSeleccionado<tamMaxPermitido){
        
          this.setFile(file);    
          
          this.cargando = 0;
          //this.uploadDocFile(formData);
        }else{

          this.showModal=true;
          this.modalMessage=''+"El archivo no se pudo cargar. El limite de tamaño de archivo permitido para este tipo de documento es de "+tamMaxPermitido+" MByte(s), Por favor reduzca el tamaño del archivo e intente nuevamente";
          this.cargando=0;
          this.$refs['comprobante'+this.cursoData.c0+'Ref'].value="";

        }
      }else{

        this.showModal=true;
        this.modalMessage=''+"Error: "+"Error el tipo de Archivo seleccionado: "+ext+" no concuerda con el tipo especificado: .png";
        
        this.cargando=0;
        this.$refs['comprobante'+this.cursoData.c0+'Ref'].value="";
      }

    },

    setPictureData(data){
      /*
      console.log("setPictureData():");
      console.log(data);
      console.log(data.target.result);
      */
      
      //this.fileSrc = data.target.result;

      this.userStore.loadingFile.fileSrc = data.target.result;

      this.$emit('showFileModalEv');

    },

    setFile(file){
      console.log("setFile()");
      console.log("File:");
      console.log(file);

      let fr = new FileReader();
      
      //let srcStr = 'unset';

      fr.onload = this.setPictureData;

      /*
      console.log("srcStr");
      console.log(srcStr);
      */

      this.userStore.loadingFile.file = file;
      
      fr.readAsDataURL(file);

    },

    getConstancia(key,dateStr){

      let link='https://www.sitioliceo.com/scLCcur/api/superSC/v1/constancia.php?id='+key+'&fecha='+dateStr;

      window.open(link);

    },

    setSelected(){
      //alert("setSelected()");
      let param = {val:''+this.cursoData.c37};
      this.$emit('setSelectedCursoEv',param);

    }


  },
  computed:{
    ...mapStores(useUserStore),
  }
  
  

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@keyframes jello {
  11.1% {
    transform: none
  }
  22.2% {
    transform: skewX(-5.5deg) skewY(-5.5deg)
  }
  33.3% {
    transform: skewX(4.25deg) skewY(4.25deg)
  }
  44.4% {
    transform: skewX(-2.525deg) skewY(-2.525deg)
  }
  55.5% {
    transform: skewX(1.3625deg) skewY(1.3625deg)
  }
  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg)
  }
  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg)
  }
  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg)
  }
  100% {
    transform: none
  }
}


@keyframes jello2 {
  11.1% {
    transform: none
  }
  22.2% {
    transform: skewX(-5.5deg) skewY(-5.5deg)
  }
  
  33.4% {
    transform: skewX(-2.525deg) skewY(-2.525deg)
  }
  55.5% {
    transform: skewX(1.3625deg) skewY(1.3625deg)
  }
  
  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg)
  }
  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg)
  }
  100% {
    transform: none
  }
}


.center {
  display: inline-block
}

.jello {
  animation: jello 1.5s ease;
  transform-origin: center
}

.CartaCurso{
  color:black;
  transition: all 0.5s ease;
  transform: scale(0.96);
}

.CartaCurso:hover {
  transform: scale(1);
  /*animation: jello2 0.6s ease-in;*/
  transform-origin: center;
  
  
}

.glassCard{
    
    background: rgba(255,255,255,0.30);    
    backdrop-filter: blur(2px);
    box-shadow: 0 8px 32px 0 rgba(31,38,135,0.37);    
    margin: 0 auto;
    border-radius: 10px;
    padding:10px;    
    border: 1px solid rgba(255,255,255,0.18);
  
  }


</style>
