<template>
  <div class="fullWidth white tCenter" style="padding-top: 100px;overflow-y:auto;max-height:80vh;">
    <div class="col50 block">
      <span></span>
    </div>    

    <section class="col80 center">
      <article class="tCenter">
        <img class="col40" src="./../../public/img/programaBecas.png">
      </article>

      <article>

        <h1>
          Política de Becas
        </h1>


        <div class="col90 block center tLeft">
          <div class="tCenter">
            <h3>
              Introducción
            </h3>
          </div>
          <p>
            <label>
              Fundación Antonio Chedraui Caram A.C. y Liceo de Artes y Oficios A.C., brinda el programa de Becas a todos aquellos usuarios que deseen continuar con el aprovechamiento de estudios y superación personal, brindando un porcentaje de hasta un 90% de cobertura económica en cualquiera de las ofertas educativas que se encuentren disponibles, (bachillerato, bachillerato en línea, carreras técnicas, especialidades, cursos presenciales y en línea).
            </label>
          </p>
        </div>

        <div class="col90 block center">
          <div class="tLeft">
            <h3>
              I.	Preinscripciones e ingreso
            </h3>
          </div>
          <p class="tLeft">
            La solicitud de una beca debe ser generada de manera libre e independiente para los cursos presenciales y en línea a través del portal de servicios en 
            <a href="https://www.cursos.sitioliceo.org"  style="color:blue;">
              www.cursos.sitioliceo.org 
            </a>
            con el objetivo principal de aprender y mejorar de manera personal y profesional.
          </p>
        </div>

        <div class="col90 block center">
          <div class="tLeft">
            <h3>
              II.	Cobertura
            </h3>
          </div>
          <p class="tLeft">
            La beca cubre hasta un 90% del costo real por curso (según el curso de interés y duración) y el porcentaje restante deberá ser cubierto por el aspirante/alumno con una cuota de aportación y donativo en especie según sea el caso.
          </p>
        </div>

        <div class="col90 block center">
          <div class="tLeft">
            <h3>
              III.	Compromiso
            </h3>
          </div>
          <p class="tLeft">
            El alumno debe comprometerse a la asistencia y realización del curso una vez finalizados satisfactoriamente sus procesos administrativos, todos los cursos deseablemente darán inicio con un mínimo de 15 alumnos inscritos, el plantel correspondiente (Xalapa, Veracruz o Villahermosa), se comunicará con los alumnos en un lapso de 24 a 72 hrs. Después de su preinscripción en el portal de cursos, en caso de realizarse un re-programación y el asesor educativo correspondiente indicará por medios de comunicación oficiales el inicio del curso. Los cursos están sujetos a cambios y actualizaciones, la variación de docentes está sujeta a cambios según la disponibilidad del mismo.
          </p>
        </div>

        <div class="col90 block center">
          <div class="tLeft">
            <h3>
              IV.	Procedimientos administrativos del alumno
            </h3>
          </div>
          <div class="tLeft">
          
            El alumno que sea beneficiado con una beca deberás seguir los pasos operativos y administrativos:
            <article style="margin-left:15px;">
              <div>
                <p>
                  1.  Estar pre-inscrito en portal de servicios 
                  <a href="www.cursos.sitioliceo.org" style="color:blue;">
                    www.cursos.sitioliceo.org 
                  </a>
                </p>
              </div>

              <div>
                <p>                
                  2.	Cumplir con un mínimo de 80% en asistencia y puntualidad.
                </p>
              </div>

              <div>
                <p>                
                  3.	Tres retardos que no excederán de 5 minutos equivalen a una inasistencia, si excede ese tiempo ya no podrá ingresar a su capacitación.
                </p>
              </div>

              <div>
                <p>                                
                  4.	Si no se concluye el horario completo de clases, se considera como falta.
                </p>
              </div>

              <div>
                <p>                
                  5.	Al acumular un porcentaje de faltas superior al mínimo de asistencias, no recibirá su constancia de acreditación.
                </p>
              </div>

              <div>
                <p>                
                  6.	Se deberá informar o reportar inasistencia en los siguientes tres días hábiles, si es por motivo de enfermedad (presentar documento médico), o si es una situación en particular dirigirse a la dirección o departamentos de apoyo, para considerarla.
                </p>
              </div>

              <div>
                <p>                
                  7.	Portar el uniforme correctamente desde el primer día de clases (camisa blanca y botón con logo oficial Liceo).
                </p>
              </div>

              <div>
                <p>                
                  8.	Respetar a compañeros, docentes y personal del plantel.
                </p>
              </div>

              <div>
                <p>                
                  9.	Aportar donación en especie por única vez (material de papelería, limpieza o de salud).
                </p>
              </div>

              <div>
                <p>                
                  10.	Aportar puntualmente las cuotas de aportación en una sola emisión al inicio del curso.
                </p>
              </div>

              <div>
                <p>                
                  11.	Cumplir con la labor social establecida por el plantel.
                </p>
              </div>

              <div>
                <p>                                
                  12.	Cuidar las instalaciones, mobiliario y equipo (limpio y ordenado), y aceptar pagar o reponer todo desperfecto causado por el mal uso que se realice.
                </p>
              </div>

              <div>
                <p>
                  13.	Obtener, mejorar y/o ascender en un empleo, establecer un negocio propio y mejorar nivel educativo, derivado del aprendizaje adquirido.
                </p>
              </div>

              <div>
                <p>                
                  14.	Apoyar al plantel en labores administrativas y de traslado en caso de ser requeridas.
                </p>
              </div>

              <div>
                <p>                
                  15.	Los alumnos deberán colaborar con la limpieza de las áreas ocupadas ocasionalmente con servicios de ordenamientos.
                </p>
              </div>

              <div>
                <p>                
                  16.	Ingresar con material requerido para prácticas (dando inicio a las clases no podrá salir del plantel para la adquisición de los mismos).
                </p>
              </div>

              <div>
                <p>                
                  17.	De no respetar y aplicar los lineamientos de este reglamento se procederá a la sanción por medio de reportes que se incluirán en su expediente, el tercer reporte causará BAJA INMEDIATA Y DEFINITIVA.
                </p>
              </div>

              <div>
                <p>                                
                  18.	Seguir nuestras redes sociales oficiales.
                </p>
              </div>
            </article>
          </div>
        </div>

        <div class="col90 block center">
          <div class="tLeft">
            <h3>
              V.	Traspaso de Beca
            </h3>
          </div>
          <p class="tLeft">
            Si un alumno no puede iniciar el curso al cual se ha inscrito, y solo si realizo todo el proceso de administrativo y se encuentra satisfactoriamente inscrito en el curso, podrá utilizar su cuota de aportación en cualquier otro curso ofertado que sea equivalente. El alumno que no apruebe, aplique deserción o sea suspendido del curso, deberá aplicar a una nueva beca en caso de estar interesado en otra oferta educativa.
          </p>
        </div>

        <div class="col90 block center">
          <div class="tLeft">
            <h3>
              VI.	Deserción de curso
            </h3>
          </div>
          <p class="tLeft">
            En caso de que el alumno aplique la deserción al inicio o durante el curso, no existe ningún tipo de penalización o cuota que se deba aplicar.
          </p>
        </div>

        <div class="col90 block center">
          <div class="tLeft">
            <h3>
              VII.	Planteles y plataformas virtuales
            </h3>
          </div>
          <p class="tLeft">
            Liceo de Artes y Oficios A.C. proporciona las instalaciones y plataformas virtuales necesarias para facilitar la experiencia de capacitación y aprendizaje de todos los programas académicos, las plataformas digitales son únicos y exclusivos para uso de aprendizaje.
          </p>
        </div>

        <div class="col90 block center">
          <div class="tLeft">
            <h3>
              VIII.	Constancias Digitales
            </h3>
          </div>
          <p class="tLeft">
            Cuando el alumno finalice satisfactoriamente su curso junto con los procesos administrativos, se realiza el envío del enlace por medios de comunicación oficiales y se descargue la constancia por medio del portal oficial de Descarga y Verificación 
            <a href="www.constancias.sitioliceo.org" style="color:blue;">
              www.constancias.sitioliceo.org 
            </a>
          </p>
        </div>

      </article>      

    </section>

    <div>
      <button class="btnBlue col50" @click="toHome()">
        Volver al Inicio
      </button>
    </div>

    <div style="min-height:150px;">
        <span>
        </span>
    </div>

  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useUserStore } from '../store/user';

export default {
  name: 'PoliticaBecas',
  methods:{
    toHome(){
        this.userStore.login=0;
        this.$root.changeRoute("/");
      }
  },
  computed:{
    ...mapStores(useUserStore),
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
