<template>
  <div class="fullWidth" style="color:black;">

    <div class="col49 center colSm95">
      <div class="formRow col60 center colSm95">
            
        <div class="formCol col95 colSm95 tCenter" style="margin-top:10px;">
          <div>
            <div class="col50 row center">
              <img src="img/usuario.png" class="col50 row center" style="vertical-align:middle;">
            </div>
            <div class="col95 center">
              <h3 style="font-family: town;vertical-align:middle;color:var(--orange);">
                Iniciar Sesión
              </h3>
            </div>
          </div>
          <div class="formRow colSm95">
            <label class="formLabel ">        
              Correo Electrónico:
            </label>
            <span style="min-width:5%;width:5%;"></span>
            <input-text ref="correoRef" :propName="'correo'"  :whiteBackground="false" :mode="'email'" 
            :placeholder="'Correo Electrónico'" :capital="false" :maxLength="120" :minLength="5"></input-text>
          </div>
        </div>
        <div class="formCol col95 colSm95">
          <div class="formRow colSm95">
            <label class="formLabel ">
              Contraseña:
            </label>
            <span style="min-width:5%;width:5%;"></span>
            <input-password ref="passwordRef" :propName="'password'"  :whiteBackground="false" :mode="'AN'" 
            :placeholder="'Contraseña'" :capital="false" :maxLength="20" :minLength="2"></input-password>
          </div>
        </div>
        
        <div class="formCol col95 colSm95">
          <button class="btnBlue col95 center" v-on:click="sendLogin()"                
          >
            <i class="bi bi-pencil-square"></i>
            Iniciar Sesión
          </button>
        </div>
                            
      </div>
    </div>


    <modal title="Registro" v-if="showModal" v-on:closeModalEv="closeModal()" style="display:block;left:10vw;top:10vh;height:30vh;">
      <div style="text-align:center;overflow-y:auto;">
        <h3 style="color:black;">
          {{modalMessage}}
        </h3>
      </div>
    </modal>

  </div>
</template>

<script>

import { mapStores } from 'pinia';
import { useUserStore } from '../store/user';
import InputText from './Inputs/InputText.vue';
import InputPassword from './Inputs/InputPassword.vue';

import Modal from './Modal/Modal.vue';


export default {
  name: 'RegistroCuentaAlumno',
  components:{
    Modal,
    InputText,    
    InputPassword
  },
  mounted:function(){

    this.confirmado=false;
    this.userStore.currentView='REGISTRO';
    this.getCurrentVersion();

  },
  data:function(){
    return{

      currentVersion:'',
      verMsg:'',
      alumnoData:{},
      confirmado:false,
      showModalPrivacidad:false,
      showModal:false,
      showNuevoRegistro:false,
      modalMessage:'',
      procesando:0,
      modalidad:'',
      listaPlanteles:[{c1:'XALAPA'},{c1:'VERACRUZ'},{c1:'VILLAHERMOSA'}],
      listaModalidad:[{c0:'ID',c1:'PRESENCIAL'},{c0:'ID',c1:'EN LÍNEA'}],
      listaDuracion:[{c0:'ID',c1:'SESIÓN UNICA'},{c0:'ID',c1:'SEMANAL'},{c0:'ID',c1:'MENSUAL'},{c0:'ID',c1:'TRIMESTRAL'},{c0:'ID',c1:'SEMESTRAL'}],
      listaCursos:['Maquillaje básico','Manejo del Portal SAT','Educación Financiera'],
      listaHorarios:[],
      listaRelacionChedraui:[{c1:'EXTERNO',c2:'E'},{c1:'COLABORADOR',c2:'C'},{c1:'FAMILIAR DE COLABORADOR',c2:'F'}],
      formData:{
        'password':{data:"",state:0},
        'modalidad':{data:"",state:0},
        'duracion':{data:"",state:0},
        'plantel':{data:"",state:0},
        'carrera':{data:"",state:0,optional:true},
        'correo':{data:"",state:0},
        'telefono':{data:"",state:0},
        'nombre':{data:"",state:0},
        'ap1':{data:"",state:0},
        'ap2':{data:"",state:0},
        'curp':{data:"",state:0},        
        'userName':{data:"",state:0},        
        'horario':{data:"",state:0},        
        'estado':{data:"",state:0},        
        'municipio':{data:"",state:0},        
        'secundaria':{data:"",state:0},        
        'promedio':{data:"",state:0},
        'relacionChedraui':{data:"",state:0},
      },
    };    
  },
  methods:{

    getCurrentVersion(){

      let data={};

      console.log("data:");
      console.log(data);
      
      this.$root.sendPOSTRequest(
      "https://www.sitioliceo.com/scLCcur/api/superSC/v1/alumnos/verificarVersionPortalCursos/",
      data,
      this.onSuccessVersion,
      this.onErrorVersion);

    },
    onSuccessVersion(data){

      console.log(data);
      
      this.userStore.showLogin=(data.acceso=='1');
      this.userStore.showRegistro=(data.registro=='1');
      this.setVersionState(data.version);

    },
    onErrorVersion(error){
      console.log(error);

    },

    setVersionState(currentVersion){
      console.log('currentVersion:');
      console.log(currentVersion);
      let version=currentVersion;

        //localStorage.clear();
        if(localStorage.estado){
            let estadoLoaded=JSON.parse(localStorage.estado);
            //this.version=estadoLoaded.version;
            //this.versionActual=version;
            //alert("Version actual: "+estadoLoaded.version);
            console.log("Estado:");
            console.log(localStorage.estado);
            console.log("estadoLoaded.version="+estadoLoaded.version);
            console.log("currentVersion="+currentVersion);

            if(estadoLoaded.version!=version){
              let estado={
                
                version:""+version,
                actualizado:true
                
              };
              
              localStorage.estado=JSON.stringify(estado);
              //this.userData.version=currentVersion;
              //alert("Estado Actualizado");
              this.verMsg="Versión Actualizada: "+this.currentVersion;
              console.log("Versión Actualizada");
              //alert("Versión Actualizada");
              console.log(localStorage.estado);
              location.reload(true);
            }
            this.currentVersion=currentVersion;

        }else{
          let estado={            
            version:""+currentVersion,
            actualizado:true
          };
          //this.userData.version=currentVersion;
          localStorage.estado=JSON.stringify(estado);
          //alert("Estado Guardado");
          this.verMsg="Versión guardada";
          console.log(localStorage.estado);
          this.currentVersion=currentVersion;
        }

    },

    closeModal(){
      this.showModal = false;
    },
    sendLogin(){
      console.log("sendLogin()");
      //let passw = this.$root.genPSW(8);
      let data = {
        correo:''+this.formData.correo.data,        
        psw:''+this.formData.password.data,        
      };
      console.log("data: ");
      console.log(data);
      this.$root.sendPOSTRequest(
        "https://www.sitioliceo.com/scLCcur/api/superSC/v1/alumnos/getAlumnoData/",
        data,
        this.onSuccessSendLogin
      );
    },
    onSuccessSendLogin(data){
      console.log("onSuccessSendLogin:");
      console.log(data.data);

      if(data.data!==null){
        this.$root.importData(data.data);
      }

      
    },

  },
  computed:{
    ...mapStores(useUserStore),
    
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.glassModulo{
    
    z-index:100;  
    
    background: rgba(255,255,255,0.30);    
    backdrop-filter: blur(2px);
    box-shadow: 0 10px 32px 0 rgba(31,38,135,0.37);    
    margin: 0 auto;
    margin-bottom: 0px;
    border-radius: 10px;
    padding:5px;
    border: 1px solid rgba(255,255,255,0.18);
    
  }

</style>
