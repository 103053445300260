<template>
  <section :class="'vistaCurso '+displayClass" style="font-size:1rem;overflow-y:auto;">
    
    <article class=" block tCenter" style="height:150vh;">
      <div :class="'primaryBg fullWidth'" style="height:25vh;top:0;margin-bottom:20px;">

        <div :class="'fullWidth tCenter '+(cursoData.c5=='EN LÍNEA'?'orangeBg':'primaryBg')" style="height:5vh;">
          <div class="col95 row" style="vertical-align:middle;padding-top:10px;">
            <strong>
              {{cursoData.c5}}
            </strong>

            <button :class="'right '+(cursoData.c5=='EN LÍNEA'?'orange':'primary')" @click="cerrar()" style="background:none;border:none;color:white;cursor:pointer;">
              CERRAR <i :class="'bi bi-x-lg'"></i>
            </button>

          </div>
          <!--
          <div class="col25 row" style="vertical-align:middle;">
            
          </div>
          -->
        </div>


        <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="max-height:40vh;position:relative;bottom:0;margin-top:50px;"
        viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
        <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
        </defs>
        <g :class="waveAnimation">
        <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
        <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
        <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
        <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
        </g>
        </svg>
        

        <div class="fullWidth colSm90 block tCenter" 
        style="position:relative;top:-140px;z-index:100;background:rgba(220,220,220,0.2);overflow:hidden;">

          <div class="col40 colSm90 row tCenter hideDesktop" 
          style="margin:0 auto;overflow:hidden;border-radius:5px;max-width:5vw;">
            <img :src="cursoData.c2" class="col60" style="object-fit:contain;">
          </div>


          <div class="col60 colSm90 row tCenter " 
          style="margin:0 auto;overflow:hidden;padding-left:15px;">
            <h2 :class="(cursoData.c5=='EN LÍNEA'?'whiteText':'whiteText')+' titleSize'" style="margin-bottom:3px;text-shadow: 1px 0px var(--blue);">
              <!--TITULO DEL CURSO-->
              {{cursoData.c1}}
            </h2>

          </div>

          <div class="col40 row tCenter hideMobile" 
          style="background:none;margin:0 auto;overflow:hidden;border-radius:5px;">
            <!--IMAGEN DEL CURSO-->
            <img :src="cursoData.c2" class="col50" style="object-fit:cover;max-height:25vh;">
          </div>

        </div>



        


        
      </div>
<!--
      <div class="hideDesktop colSm95" style="height:15vh;">
        <span>
        </span>
      </div>
-->
      <div class="col95 center" style="margin-top:50px;padding:0px;">
        
        
        <article class="tRight">

          <section class="fullWidth" style="color:black;">

            <div class="col30 colSm95 center row tLeft hideMobile" style="vertical-align:top;">
            </div>

            <div class="col50 colSm95 center row tRight" style="background:none;vertical-align:top;margin-top:20px;">
              
              <div class="hideDesktop colSm95" style="min-height:30px;">
                <span>
                </span>
              </div>

              <div class="col95 row">
                Aportación:                
                <strong style="font-size:1.4rem;">
                  ${{cursoData.c11}}
                </strong>
              </div>

              <div class="fullWidth row tCenter" style="background:none;">


                <!--
                <button class="btnBlue col40 colSm50 row rowm">
                  <i class="bi bi-download"></i>
                  <br class="hideDesktop">
                  <small>
                    Descargar Contenido <br class="hideDesktop colSm10">del Curso
                  </small>
                </button>
                -->

                <button class="btnDefault col50 colSm50 row rowm" @click="showModalSeleccionarHorario=true;modalHorarioMsg='Terminos del servicio';modo='CARRITO';" v-if="!isCursoInCarrito()&&cursoData.c22=='A'">
                  <i class="bi bi-cart-plus-fill"></i>
                  <small>
                    Agregar al <br class="hideDesktop colSm10">Carrito
                  </small>
                </button>

                <button class="btnDefault col50 colSm50 row rowm" v-if="isCursoInCarrito()">
                  <small>
                    <i class="bi bi-cart-fill"></i>
                    <small>
                      Curso ya en Carrito
                    </small>
                  </small>
                </button>                

                <button class="btnVerde col50 colSm100 row rowm" @click="showModalSeleccionarHorario=true;modalHorarioMsg='Terminos del servicio';modo='INSCRIBIR';" v-if="!isCursoInCarrito()&&cursoData.c22=='A'">
                  <small>
                    <i class="bi bi-pencil-square"></i>
                    <br class="hideDesktop">
                    Inscribir
                  </small>
                </button>

              </div>


            </div>
            
          </section>
        </article>
        <article class="scrollDesktop" style="margin-top:20px;background:none;">


          <section class="col18 colSm95 smCenter row " style="top:0;color:black;text-align:left;border-right:solid 2px gray;padding-right:10px;margin-right:5px;">

            <div class="col70 colSm95 center row tLeft hideDesktop" style="vertical-align:top;">

              <div>
                Duración del Curso:
                <strong>
                  {{cursoData.c18}}
                </strong>
              </div>              

            </div>



            <div class="tCenter">

              <div class="btnOrange">
                Duración del Curso:
                <strong>
                  {{cursoData.c18}}
                </strong>
              </div>

              <h2 style="font-family: town;color:var(--orange);">
                <!--
                  style="text-shadow:  1px 1px 10px rgba(31,38,135,0.6);"
                  -->
                <strong >
                  Horarios disponibles:
                </strong>
              </h2>
            </div>

            

            <div style="overflow-y:auto;max-height: 200px;height:300px;">

              <button class="glassModulo col95" v-for="hor in listaGruposHorario" :key="hor">
                <div class="btnBlue">
                  Horario {{hor.c15}}
                </div>

                <div>
                  <strong>
                    Docente:
                  </strong>
                  <p>
                    {{hor.c16}}
                  </p>

                </div>

                <div class="hideMobile">
                  <div v-if="hor.c1!=''">
                    Lunes: 
                    <strong>
                      {{hor.c1}} - {{hor.c2}}
                    </strong>
                  </div>
                  <div v-if="hor.c3!=''">
                    Martes: 
                    <strong>
                      {{hor.c3}} - {{hor.c4}}
                    </strong>
                  </div>
                  <div v-if="hor.c5!=''">
                    Miercoles:
                    <strong>
                      {{hor.c5}} - {{hor.c6}}
                    </strong>
                  </div>
                  <div v-if="hor.c7!=''">
                    Jueves:
                    <strong>
                      {{hor.c7}} - {{hor.c8}}
                    </strong>
                  </div>
                  <div v-if="hor.c9!=''">
                    Viernes:
                    <strong>
                      {{hor.c9}} - {{hor.c10}}
                    </strong>
                  </div>
                  <div v-if="hor.c11!=''">
                    Sabado:
                    <strong>
                      {{hor.c11}} - {{hor.c12}}
                    </strong>
                  </div>
                  <div v-if="hor.c13!=''">
                    Domingo:
                    <strong>
                      {{hor.c13}} - {{hor.c14}}
                    </strong>
                  </div>
                </div>

                <div class="hideDesktop colSm95">
                  <div class="col48 row">
                    <div v-if="hor.c1!=''">
                      Lunes: 
                      <strong>
                      {{hor.c1}} - {{hor.c2}}
                    </strong>
                    </div>
                    <div v-if="hor.c3!=''">
                      Martes: 
                      <strong>
                        {{hor.c3}} - {{hor.c4}}
                      </strong>
                    </div>
                    <div v-if="hor.c5!=''">
                      Miercoles:
                      <strong>
                        {{hor.c5}} - {{hor.c6}}
                      </strong>
                    </div>
                    <div v-if="hor.c7!=''">
                      Jueves:
                      <strong>
                        {{hor.c7}} - {{hor.c8}}
                      </strong>
                    </div>

                  </div>

                  <div class="col48 row" style="margin-left:5px;">

                    <div v-if="hor.c9!=''">
                      Viernes:
                      <strong>
                        {{hor.c9}} - {{hor.c10}}
                      </strong>
                    </div>
                    <div v-if="hor.c11!=''">
                      Sabado:
                      <strong>
                        {{hor.c11}} - {{hor.c12}}
                      </strong>
                    </div>
                    <div v-if="hor.c13!=''">
                      Domingo:
                      <strong>
                        {{hor.c13}} - {{hor.c14}}
                      </strong>
                    </div>

                  </div>

                </div>

                <div>
                  <strong>
                    Periodo del curso: 
                  </strong>
                  {{fechaToStr(hor.c17)}} al {{fechaToStr(hor.c18)}}
                </div>


              </button>

            </div>

            

            
              
              

          </section>


          <section class="col80 colSm95 row" style="color:black;height:50vh;">

            <article class="tCenter">
              <div>
                <h2 style="font-family: town;color:var(--orange);">
                <!--
                  style="text-shadow: 1px 1px 10px rgba(31,38,135,0.6);"
                  -->
                  <strong >
                    Descripción
                  </strong>
                </h2>

              </div>

              <div class="col90 colSm95 center tLeft" style="border-left: solid 2px darkgray;padding-left:5px;">
                {{cursoData.c13}}
              </div>
            </article>


            <article class="tCenter">
              <div>
                <h2 style="font-family: town;color:var(--orange);">
                <!--
                  style="text-shadow: 1px 1px 10px rgba(31,38,135,0.6);"
                  -->
                  <strong >
                    Requerimientos del Curso:
                  </strong>
                </h2>
              </div>

              <div class="col90 colSm95 center tLeft" style="border-left: solid 2px darkgray;padding-left:5px;">
                {{cursoData.c12}}
              </div>
            </article>


            <article>
              <div>
                <h2 style="font-family: town;color:var(--orange);">
                <!--
                  style="text-shadow:  1px 1px 10px rgba(31,38,135,0.6);"
                  -->
                  <strong >
                    Perfil de Ingreso
                  </strong>
                </h2>
              </div>

              <div class="col90 colSm95 center tLeft" style="border-left: solid 2px darkgray;padding-left:5px;">
                {{cursoData.c14}}
              </div>
            </article>

            <article>
              <div>
                <h2 style="font-family: town;color:var(--orange);">
                <!--
                  style="text-shadow: 1px 1px 10px rgba(31,38,135,0.6);"
                  -->
                  <strong >
                    Perfil de Egreso
                  </strong>
                </h2>
              </div>

              <div class="col90 colSm95 center tLeft" style="border-left: solid 2px darkgray;padding-left:5px;">
                {{cursoData.c15}}
              </div>
            </article>

            <article>
              <div>
                <h2 style="font-family: town;color:var(--orange);">
                <!--
                  style="text-shadow:  1px 1px 10px rgba(31,38,135,0.6);"
                  -->
                  <strong >
                    Contenido del Curso
                  </strong>
                </h2>

                
              </div>

              <div class="col95 center tCenter">
              
                <div class="tLeft" v-for="(modulo,mI) in listaModulos" :key="modulo" style="margin-top:5px;">
                  <div class="glassModulo" @click="openModulo(modulo)">
                    {{mI+1}} | 
                    <strong>
                      {{modulo.c1}}
                    </strong>
                    <i class="bi bi-chevron-down right"></i>
                    
                  </div>
                  <div v-if="modulo.display" style="background:white;border:solid lightgray 1px;padding:5px;border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;border-top:none;margin-top:-5px;width:98%;">
                    <div style="margin-top:5px;">
                      <strong>
                        Descripción:
                      </strong>
                    </div>
                    <div style="margin-top:5px;">
                      {{modulo.c2}}
                      
                    </div>
                    <strong  style="margin-top:5px;">
                      Temas:
                    </strong>
                    <div style="margin-top:5px;">
                      {{modulo.c2}}
                      <ul>
                        <li v-for="(tema) in modulo.listaTemas" :key="tema">
                          {{tema.c0}}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              
            </article>

            <article style="min-height:500px;">
              <span></span>
            </article>

          </section>

          

          
        
        
        </article>
        

        <!--
        <div>
          <small style="color:gray;">
            <i class="bi bi-person-vcard"></i>{{cursoData.c10}}
          </small>
        </div>
        <div>
          <div class="btn btnOrange col50 row">
            <i class="bi bi-file-earmark-text-fill"></i>Ver Curso
          </div>
          <div class="btn btnBlue col50 row">
            <i class="bi bi-pencil-square"></i>Inscribirme
          </div>
        </div>
        -->

      </div>

      

      <!--
      <div class="formRow col90 center glass tCenter">
    
        <div class="formRow colSm95" v-if="procesando==0">
          
          <button class="btn primary btnWide" 
          >Registrar</button>
                
        </div>
        
      </div>
      -->

    </article>

    <modal title="Oferta de Cursos" v-if="showModal" v-on:closeModalEv="closeModal()" style="display:block;left:10vw;top:10vh;height:30vh;">              
      <div style="text-align:center;overflow-y:auto;">
        <h3 style="color:black;">

          {{modalMessage}}
          
        </h3>
        
      </div>
      
    </modal>


    <modal :title="modalHorarioMsg" v-if="showModalSeleccionarHorario" v-on:closeModalEv="showModalSeleccionarHorario=false;terminosAceptados=false;" style="display:block;left:10vw;top:10vh;height:30vh;">

      <div v-if="!terminosAceptados">

        <h3 style="color:black;">
          Para continuar lee y acepta los términos del servicio
        </h3>

        <div style="overflow-y:auto;max-height:45vh;height:70vh;color:black;">

          <p class="col98 center" style="background:gray;color:white;border-radius:5px;">
            <i>
            Esta organización está comprometida con la seguridad de los datos de sus usuarios. Cuando le pedimos llenar los campos de información personal con la cual usted pueda ser identificado, lo hacemos asegurando que sólo se empleará de acuerdo con los términos de este documento. Sin embargo, esta Política de Privacidad puede cambiar con el tiempo o ser actualizada por lo que le sugerimos revisar continuamente esta página para asegurarse que está de acuerdo con dichos cambios.
            <br>
            <br>
            Nuestro sitio web podrá recoger información personal, por ejemplo: Nombre, información de contacto como su dirección de correo electrónica e información demográfica. Así mismo cuando sea necesario podrá ser requerida información específica para procesar algún trámite o servicio proporcionado por la administración general (Ejemplo: preinscripción, inscripción, acceso a plataformas educativas propias y auxiliares).
            </i>

          </p>
          <p class="col98 center" style="margin-top:10px;">
            <br>
            <strong>
              Uso de la información recogida:
            </strong>
            <br>
            <br>            
            Nuestro sitio web emplea la información con el fin de proporcionar el mejor servicio posible, particularmente para mantener un registro de usuarios, sus preferencias de navegación e incidencias dentro de la página. Es posible que sean enviados correos electrónicos periódicamente a través de nuestro sitio con oferta académica, invitación a eventos, información de campañas de acción social y otra información publicitaria que consideremos relevante para usted o que pueda brindarle algún beneficio, estos correos electrónicos serán enviados a la dirección que usted proporcione y podrán ser cancelados en cualquier momento. Liceo de Artes y Oficios A.C. está altamente comprometido para cumplir con el compromiso de mantener su información segura.
          </p>
          <p class="col98 center" style="margin-top:20px;">
            <strong>
              Uso de Cookies: 
            </strong>          
            <br>
            <br>
            Una cookie se refiere a un fichero que es enviado con la finalidad de solicitar permiso para almacenarse en su ordenador, al aceptar dicho fichero se crea y la cookie sirve entonces para tener información respecto al tráfico web, y también facilita las futuras visitas a una web recurrente. Otra función que tienen las cookies es que con ellas la web pueden reconocerte individualmente y por tanto brindarte el mejor servicio personalizado de su web. Nuestro sitio web emplea las cookies para poder identificar las páginas que son visitadas y su frecuencia. Esta información es empleada únicamente para análisis estadístico y después la información se elimina de forma permanente. Usted puede eliminar las cookies en cualquier momento desde su ordenador. Sin embargo, las cookies ayudan a proporcionar un mejor servicio de los sitios web. Usted puede aceptar o negar el uso de cookies, sin embargo, la mayoría de navegadores aceptan cookies automáticamente pues sirve para tener un mejor servicio web. De tal forma que puede cambiar la configuración de su ordenador para declinar las cookies.
          </p>
          <p class="col98 center" style="margin-top:20px;">
            <strong>
              Contenidos:
            </strong>
            <br>
            <br>
            Los contenidos publicados en el sitio web <a href="http://www.liceodeartesyoficios.org/">http://www.liceodeartesyoficios.org/</a> son exclusivamente de carácter informativo. La información que se presenta es, en la mayoría de los casos, proporcionada por cada plantel educativo. Sobre la veracidad y exactitud de la información, los responsables del sitio, se encargan de verificar la veracidad y exactitud de la información que se presente. En los casos en que exista alguna corrección u observación de los contenidos del sitio se deberá enviar a través de uno de los métodos de contacto que son mencionados en la página principal (teléfono, correo electrónico o presencialmente). Sobre la disponibilidad del servicio, los responsables de este sitio web se comprometen a hacer todo lo posible para tenerlo siempre disponible al público, sin embargo, no nos hacemos responsables de problemas técnicos fuera de nuestro control que ocasione fallas temporales. El material contenido es generado por personal de la comunidad educativa Liceo de Artes y Oficios A.C. dueño único y exclusivo, de todos los derechos, título e intereses en y del Sitio Web, de todo el contenido (incluyendo, por ejemplo, audio, fotografías, ilustraciones, gráficos, otros medios visuales, videos, copias, textos, software, etc.) Exceptuando contenido informativo de carácter público proporcionado por páginas externas (Dirección General de Bachillerato, Secretaría de Trabajo y Previsión Social, etc.). El uso del Sitio Web no le otorga propiedad de ninguno de los contenidos.
          </p>

          <p class="col98 center" style="margin-top:20px;">
            <strong>
              Licencia de uso para estudiantes: 
            </strong>
            <br>
            <br>
            Para poder utilizar el sitio   no existe un mínimo de edad, pero puesto que los programas están enfocados en estudiantes, empleados y reclutadores, se recomienda que estos se distribuyan entre mayores de 15 años. En este caso y al tratarse de menores de edad, estos deberán contar con la autorización del padre o tutor (NO aplica para cursos en áreas de belleza y gastronomía, se hará la excepción cuando se oferte un curso de las áreas mencionadas, exclusivo para menores de edad).
            Los cursos deberán desarrollarse exclusivamente dentro de la página de <a href="http://www.cursos.sitioliceo.org/">http://www.cursos.sitioliceo.org/</a>; queda prohibida su reproducción total o parcial sin la autorización expresa y por escrito por parte de Liceo de Artes y Oficios A. C.
            <br>
            Los cursos adquiridos tienen una garantía de acceso de 12 meses a partir de la adquisición del mismo, después de dicho periodo se podrá modificar, actualizar o remover dicho material sin previo aviso. 
            <br>
            <br>
            <i>
            En caso de terminación o cancelación del curso por cualquier causa, previo a la culminación del mismo, se bonificará al estudiante en su cuenta la donación a fin de que pueda adquirir un nuevo curso en el sitio. Debido a la naturaleza de la plataforma y a que los cursos pueden ser consumidos al hacerse el pago, no existe la posibilidad de solicitar un reembolso por la inscripción de un curso.
            </i>
          </p>

          <p class="col98 center" style="margin-top:20px;">
            <strong>
              Derechos de Propiedad Intelectual: 
            </strong>
            <br>
            <br>
            Este Sitio Web y todos sus derechos, título e intereses son propiedad única de Liceo de Artes y Oficios A.C. representante exclusivo de la marca en México y Latinoamérica, se encuentran protegidos por las leyes mexicanas de derechos de autor y de los tratados internacionales.
          </p>

          <p class="col98 center" style="margin-top:20px;">
            <strong>
              Limitación de responsabilidad:
            </strong>
            <br>
            <br>
            Liceo de Artes y Oficios A.C. no asume ninguna responsabilidad por material, contenido y/u opiniones publicados en este Sitio por los usuarios, por tanto, no tiene responsabilidad por sus actividades, omisiones y/ o conducta. Asimismo, Liceo de Artes y Oficios A.C., no asume responsabilidad respecto a los cursos publicados y no garantiza la enseñanza o habilidad a adquirir por el usuario. 
          </p>


          <p class="col98 center" style="margin-top:20px;">
            <strong>
              Enmiendas a este acuerdo y cambios en la plataforma: 
            </strong>
            <br>
            <br>
            Liceo de Artes y Oficios A.C.  podrá revisar estos términos y condiciones en cualquier momento y actualizar su contenido a su exclusivo criterio, entrando en vigor a partir de su publicación en este Sitio. Cualquier uso de la plataforma se considerará como aceptación de Usted a los términos y condiciones de uso aquí descritos. Si en cualquier momento Usted encuentra los términos inaceptables, Usted no deberá usar esta plataforma.
            
          </p>

        </div>

        <div class="fullWidth" style="margin-top:20px;">

          <button class="btnBlue col50 row" @click="terminosAceptados=true;modalHorarioMsg='Selección de Horario';">
            <i class="bi bi-check2-square"></i>
            Aceptar términos del servicio
          </button>

          <button class="btnErrorDark col50 row" @click="showModalSeleccionarHorario=false;">
            <i class="bi bi-x-lg"></i>
            No aceptar términos del servicio
          </button>

        </div>


      </div>




      <div style="text-align:center;overflow-y:auto;" v-if="terminosAceptados">

        <div>
          <h3 style="color:black;">

            <strong>
              Selecciona el horario para continuar
            </strong>
            
          </h3>
        </div>

        <div class="fullWidth" style="max-height:60vh;height:70vh;overflow-y:auto;">
          <div class="glassModulo col95" v-for="hor in listaGruposHorario" :key="hor" style="color:black;cursor:inherit;">

            <div class="col80 row colSm95 tLeft">
              <div class="btnBlue">
                Horario {{hor.c15}} - {{hor.c0}}
              </div>

              <div class="col50 colSm95 row">
                <strong>
                  Docente:
                </strong>
                <p>
                  {{hor.c16}}
                </p>
              </div>
              <div class="col50 row hideMobile tRight">
                <div v-if="hor.c1!=''">
                  Lunes: 
                  <strong>
                    {{hor.c1}} - {{hor.c2}}
                  </strong>
                </div>
                <div v-if="hor.c3!=''">
                  Martes: 
                  <strong>
                    {{hor.c3}} - {{hor.c4}}
                  </strong>
                </div>
                <div v-if="hor.c5!=''">
                  Miercoles:
                  <strong>
                    {{hor.c5}} - {{hor.c6}}
                  </strong>
                </div>
                <div v-if="hor.c7!=''">
                  Jueves:
                  <strong>
                    {{hor.c7}} - {{hor.c8}}
                  </strong>
                </div>
                <div v-if="hor.c9!=''">
                  Viernes:
                  <strong>
                    {{hor.c9}} - {{hor.c10}}
                  </strong>
                </div>
                <div v-if="hor.c11!=''">
                  Sábado:
                  <strong>
                    {{hor.c11}} - {{hor.c12}}
                  </strong>
                </div>
                <div v-if="hor.c13!=''">
                  Domingo:
                  <strong>
                    {{hor.c13}} - {{hor.c14}}
                  </strong>
                </div>
              </div>
              <div class="hideDesktop colSm95">
                <div class="col95 row">
                  <div v-if="hor.c1!=''">
                    Lunes: 
                    <strong>
                    {{hor.c1}} - {{hor.c2}}
                  </strong>
                  </div>
                  <div v-if="hor.c3!=''">
                    Martes: 
                    <strong>
                      {{hor.c3}} - {{hor.c4}}
                    </strong>
                  </div>
                  <div v-if="hor.c5!=''">
                    Miercoles:
                    <strong>
                      {{hor.c5}} - {{hor.c6}}
                    </strong>
                  </div>
                  <div v-if="hor.c7!=''">
                    Jueves:
                    <strong>
                      {{hor.c7}} - {{hor.c8}}
                    </strong>
                  </div>
                </div>
                <div class="col48 row" style="margin-left:5px;">
                  <div v-if="hor.c9!=''">
                    Viernes:
                    <strong>
                      {{hor.c9}} - {{hor.c10}}
                    </strong>
                  </div>
                  <div v-if="hor.c11!=''">
                    Sábado:
                    <strong>
                      {{hor.c11}} - {{hor.c12}}
                    </strong>
                  </div>
                  <div v-if="hor.c13!=''">
                    Domingo:
                    <strong>
                      {{hor.c13}} - {{hor.c14}}
                    </strong>
                  </div>
                </div>
              </div>
              <div>
                <strong>
                  Periodo del curso: 
                </strong>
                {{fechaToStr(hor.c17)}} al {{fechaToStr(hor.c18)}}
              </div>
            </div>

            <button class="btnOrange col20 row colSm95" style="min-height:20vh;" @click="agregarCursoACarrito(hor)">
              Seleccionar Horario
            </button>

          </div>
        </div>
        
      </div>

      
    </modal>


  </section>
</template>

<script>
/*

import { mapStores } from 'pinia';
import { useUserStore } from '../../store/user';
import InputText from '../Inputs/InputText.vue';
import InputSelect from '../Inputs/InputSelect.vue';
*/

import { mapStores } from 'pinia';
import { useUserStore } from '../../store/user';
import Modal from '../Modal/Modal.vue';


export default {
  name: 'VistaCursoInscrito',
  components:{Modal},
  props:{
    cursoID:String
  },
  data:function(){
    return{
      modo:'',
      terminosAceptados:false,
      showModalSeleccionarHorario:false,
      showModal:false,
      modalMessage:'',
      modalHorarioMsg:'',
      displayClass:'',
      timeOut:null,
      cursoData:{},
      waveAnimation:'',
      listaGruposHorario:[],
      listaModulos:[]

      
    }
  },
  mounted:function(){

    this.displayClass='fromMintoMax';
    
    this.getCursoData();


    //this.cursoData = this.userStore.selectedCursoInscripcion;
    
    this.myTimeout = setTimeout(this.startAnimation, 1800);

  },
  beforeUnmount() {
    
    console.log("beforeUnmount");

  },

  methods:{

    openModulo(modulo){
      console.log("openModulo");
      
      if(!modulo.display){

        this.getListaTemasEnModuloData(modulo.c3,modulo);

      }

      modulo.display=!modulo.display;

    },

    agregarCursoACarrito(horario){

      if(this.isCursoInCarrito()==false){
        this.cursoData.horario = horario;

        this.userStore.carritoCursos[this.userStore.carritoCursos.length]=this.cursoData;
      }

      if(this.modo=='CARRITO'){

        this.showModalSeleccionarHorario=false;

      }else{
        alert("NO CARRITO");
        this.$root.changeRoute('nuevoAlumno');

      }

    },
        

    fechaToStr(fechaStr){

      let anio = ''+fechaStr[0]+fechaStr[1]+fechaStr[2]+fechaStr[3];
      let meses = {
        '1':'Enero',
        '2':'Febrero',
        '3':'Marzo',
        '4':'Abril',
        '5':'Mayo',
        '6':'Junio',
        '7':'Julio',
        '8':'Agosto',
        '9':'Septiembre',
        '10':'Octubre',
        '11':'Noviembre',
        '12':'Diciembre'
      };
      let mesN = parseInt(''+fechaStr[5]+fechaStr[6]);

      let mes = ''+meses[''+mesN];

      let dia = ''+fechaStr[8]+fechaStr[9];

      return ''+dia+' de '+mes+' de '+anio;

    },

    isCursoInCarrito(){

      let l = this.userStore.carritoCursos.length;

      for(let i=0;i<l;i++){

        if(this.userStore.carritoCursos[i].c0==this.cursoID){

          return true;

        }

      }
      return false;

    },

    getTemasEnModulo(modulo,listaTemas){

      let l = listaTemas.length;
      
      let temasEnModulo=[];

      for(let i=0;i<l;i++){

        if(listaTemas[i].c0==modulo.c0){

          temasEnModulo[temasEnModulo.length]=listaTemas[i];

        }

      }
      modulo.c2 = temasEnModulo;

      //return modulo;

    },

    llenarTemasEnModulos(listaModulos,listaTemas){

      let l = listaModulos.length;
      
      for(let i=0;i<l;i++){

        listaModulos[i].display = false;
        this.getTemasEnModulo(listaModulos[i],listaTemas);

      }

    },


    getCursoData(){
      console.log("getCursoData()");
      let data={
        'id':''+this.cursoID,
      };
      this.$root.sendPOSTRequest(
        "https://www.sitioliceo.com/scLCcur/api/superSC/v1/alumnos/getCursoData/",
        data,
        this.onSuccessGetCursoData
      );
    },
    onSuccessGetCursoData(data){
      console.log("onSuccessGetCursoData:");
      console.log(data.data);
      this.setCursoData(data);
    },    
    setCursoData(data){
            
      //this.listaHorarios= data.listaHorarios.data;
      console.log("setCursoData():");
      console.log(data.data.data);
      this.cursoData=data.data.data;
      this.getHorarioModulosData();
  
    },

    getHorarioModulosData(){
      console.log("getHorarioModulosData()");
      let data={
        'cursoid':''+this.cursoData.c0
      };
      this.$root.sendPOSTRequest(
        "https://www.sitioliceo.com/scLCcur/api/superSC/v1/alumnos/getListaHorariosModulos/",
        data,
        this.onSuccessGetHorarioModulosData
      );
    },
    onSuccessGetHorarioModulosData(data){
      console.log("onSuccessGetHorarioModulosData:");
      console.log(data.data);
      this.setListaHorarioModulosData(data);
    },
    setListaHorarioModulosData(data){
      console.log("setListaHorarioModulosData()");
      console.log(data);
      this.listaGruposHorario = data.listaHorarios.data;
      this.listaModulos = data.listaModulos.data;
      //this.listaHorarios= data.listaHorarios.data;
      //console.log("listaCursos:");
      //console.log(this.listaCursos);
    },

    getListaTemasEnModuloData(IdModulo,modulo){
      this.moduloEnCarga = modulo;
      console.log("getListaTemasEnModuloData()");
      let data={
        'id':''+IdModulo
      };
      this.$root.sendPOSTRequest(
        "https://www.sitioliceo.com/scLCcur/api/superSC/v1/alumnos/getListaTemasEnModulo/",
        data,
        this.onSuccessGetTemasData
      );
    },
    onSuccessGetTemasData(data){
      console.log("onSuccessGetTemasData:");
      console.log(data.data.data);
      this.setListaTemasEnModulo(data.data.data);
    },
    setListaTemasEnModulo(data){
      console.log("setListaTemasEnModulo()");
      console.log(data);
      this.moduloEnCarga.listaTemas = data;
      
    },

    
    startAnimation(){
      this.waveAnimation='parallax';
    },
    cerrar(){
      this.displayClass='fromMaxtoMin';
      this.waveAnimation='';
      this.myTimeout = setTimeout(this.enviarCerrar, 750);
      //this.$emit('cerrarVistaCursoEv');
      //clearTimeout(this.myTimeout);
      

    },
    enviarCerrar(){
      console.log("Enviar Cerrar");
      
      this.$emit('cerrarVistaCursoEv')
    }

  },
  computed:{
    ...mapStores(useUserStore),
  }
  

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.vistaCurso{
  display:block;
  margin:0 auto;
  position:fixed;
  top:0;
  left:0;
  z-index:190;  
  background: rgba(255,255,255,0.90);    
  backdrop-filter: blur(2px);
  box-shadow: 0 10px 32px 0 rgba(31,38,135,0.37);
  border-radius: 10px;
  /*border: solid 2px rgba(0,0,0,0.30);*/
  overflow: hidden;

}

.glassModulo{
    
  z-index:100;  
  cursor:pointer;
  background: rgba(255,255,255,0.30);    
  backdrop-filter: blur(2px);
  box-shadow: 0 10px 32px 0 rgba(31,38,135,0.37);    
  margin: 0 auto;
  margin-bottom: 0px;
  border-radius: 10px;
  padding:10px;    
  border: 1px solid rgba(255,255,255,0.18);
  
}


</style>
