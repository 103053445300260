<template> 
  <div>  
  <select :class="getInputClass()" v-model="data">
    <option selected disabled>
      {{'Seleccione su '+optionName}}
    </option>
    <option v-for="option in optionsArray" :bind="option.c0" :key="option" 
    :value="valFieldName?
      option[''+this.valFieldName]:
      option.c1">
      {{
        displayFieldname?
        option[''+this.displayFieldname]:
        option.c1
      }}
      {{
        displayExtraField?
        option[''+this.displayExtraField]:
        ''
      }}
      {{
        displayExtraString?
        option[''+this.displayExtraString]:
        ''
      }}
    </option>
  </select>  
  <i class="bi bi-check-circle-fill fontCorrect" style="max-height:1px;font-size:1.3rem;float:right;position:relative;top:-43px;right:15px;margin-bottom:0px;" v-if="state==1"></i>    
  </div> 
</template>
<script>

export default{
 name:'InputSelect',
 data:function(){
  return{
    data:'',
    state:0

  };
 },
 mounted:function(){
    this.data='Seleccione su '+this.optionName;
    if(this.$parent.formData[''+this.propName].data!=''){
      this.data=this.$parent.formData[''+this.propName].data;
      this.state=1;
    }
 },
 props:{
  extraPropName:String,
  propName:String,
  optionName:String,
  optionsArray:Array,
  displayFieldname:String,
  valFieldName:String,
  displayExtraField:String,
  displayExtraString:String
 },
 
 watch:{
  data:function(){

    if(this.data!='Seleccione su '+this.optionName){
      this.state=1;
      if(this.propName!=''){
        //this.$parent[''+this.propName]=this.data;
        this.$parent.formData[''+this.propName]={data:''+this.data,state:this.state};
        if(this.extraPropName!=''){
          console.log("extraPropName:"+this.extraPropName);
          console.log("data:"+this.data);
          this.$parent[''+this.extraPropName]=this.data;
        }
        
      }      
    }

  }
 },
 methods:{

  getData(){
    return this.data;
  },
  getState(){
    return this.state;
  },
  getBState(){
    return (this.state==1);
  },
  getInputClass(){    

    let classStr={};

    classStr={      
        '0':'inputSelect',
        '1':'inputSelectCorrect'
    };

    return classStr[''+this.state];
  },

  
}
}
</script>
<style scoped>

</style>
